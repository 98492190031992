<template>
    <div>
      <p>{{ is_first }}</p>
      <VOnboardingWrapper ref="wrapper" :steps="steps" :class="{ 'is_first' : is_first }" />
      <!--<span id="foo">Howdy, My Friend!</span>
      <button id="bar">Click me for no reason</button>
      <button @click="() => goToStep(1)">Click to go second step</button>
      <div>
        <button @click="start">Start Onboarding</button>
        <button @click="finish">Finish Onboarding</button>
      </div>-->
    </div>
    
  </template>
  <style>
  .is_first .v-onboarding-item
  {
    width: 30rem;
  }
  </style>

  <script>
  import { defineComponent, ref, onMounted } from 'vue'
  import { VOnboardingWrapper, useVOnboarding } from 'v-onboarding'
  import 'v-onboarding/dist/style.css'
  import { useI18n } from "vue-i18n";
  import { useStore } from 'vuex'



  export default defineComponent ({
    components: {
      VOnboardingWrapper
    },
    setup(props, context) {
      const wrapper = ref(null)
      const { start, goToStep, finish } = useVOnboarding(wrapper)

      const store = useStore()
      const {t, locale} = useI18n()

      var is_first = ref(0);

      if (store.state.newUserModel.lang == "spanish")
      {
        locale.value = "es";
      }
      if (store.state.newUserModel.lang == "french")
      {
        locale.value = "fr";
      }
      if (store.state.newUserModel.lang == "italian")
      {
        locale.value = "it";
      }
      if (store.state.newUserModel.lang == "german")
      {
        locale.value = "de";
      }
      if (store.state.newUserModel.lang == "japanese")
      {
        locale.value = "jp";
      }

      

      const steps = [
        {
          attachTo: { element: '.headerUsername' },
          content: { description: t('tour1'),  html: true  },
          on: {
            beforeStep: function (options) {
              if (options.index == 0)
              {
                is_first.value = true;
              }
              
              else
              {
                is_first.value = false;
              }
            },
            afterStep: function () {
              is_first.value = false;
            }
          }
        },
        {
          attachTo: { element: '#availableModels' },
          content: {
            description: t('tour2')
          }
        },
        {
          attachTo: { element: '.numberCircle' },
          content: {
            description: t('tour3')
          }
        },
        {
          on: {
            beforeStep: function (options) {
              console.log(options.index);
              context.emit('displayScoreInfo', false);
            },
          },
          attachTo: { element: '.scoreInfo' },
          content: {
            description: t('tour4')
          },
          options:
          {
            scrollToStep: {
                enabled: true,
                options: {
                  behavior: 'smooth',
                  block: 'center',
                  inline: 'end'
                }
            },
          }
        },
        {
          attachTo: { element: '.scoreButtons' },
          content: {
            html: true,
            description: t('tour5')
          },
          options:
          {
            scrollToStep: {
                enabled: true,
                options: {
                  behavior: 'smooth',
                  block: 'center',
                  inline: 'end'
                }
            },
          }
        },
        {
          attachTo: { element: '.submitScoresBtn' },
          content: {
            html: true,
            description: t('tour6')
          },
          options:
          {
            scrollToStep: {
                enabled: true,
                options: {
                  behavior: 'smooth',
                  block: 'center',
                  inline: 'end'
                }
            },
          }
        },
        {
          attachTo: { element: '#dashLink' },
          content: {
            html: true,
            description: t('tour7')
          },
          options:
          {
            scrollToStep: {
                enabled: true,
                options: {
                behavior: 'smooth',
                block: 'center',
                inline: 'end'
                }
            },
          }
        }

        

        
      ]

      onMounted(() => {
        setTimeout(()=>{
          start()
        },100);
       
      })
  
      return {
        wrapper,
        steps,
        start,
        finish,
        goToStep,
        t,
        is_first
      }
    }
  })
  </script>