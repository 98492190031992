<template>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
            
            <div class="container">
                <!--<div class="row menuUserRow">
                    <div class="col-3">
                        <div class="iconWrapper">
                            <i class="bi bi-person"></i>    
                        </div>
                    </div>
                    <div class="col-9">
                        <p>My profile</p>
                    </div>
                </div>-->

                <div class="row menuUserRow" @click="this.$emit('logout')">
                    <div class="col-3">
                        <div class="iconWrapper">
                            <i class="bi bi-door-open"></i>    
                        </div>
                    </div>
                    <div class="col-9">
                        <p>{{ $t("Log out")}}</p>
                    </div>
                </div>

                <div class="row menuUserRow" @click="this.$emit('navClick', 5);">
                    <div class="col-3">
                        <div class="iconWrapper">
                            <i class="bi bi-question clickable"></i>    
                        </div>
                    </div>
                    <div class="col-9">
                        <p>{{ $t("Help")}}</p>
                    </div>
                </div>
                
                <MyReportees></MyReportees>
            </div>
        </div>
</template>

<script>

import MyReportees from "./MyReportees.vue"

export default {
    name: 'ProfileDropDown',
    components:
    {
        MyReportees
    },
    methods:
    {
    },
    data()
    {
        return {
            
        }
    },
    props:
    {
        
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

.menuUserRow, .menuUserRow .col-3
{
    align-items: center;
}

.menuUserRow
{
    margin-top: 6px;
    margin-bottom: 6px;
    cursor: pointer;
    padding-top: 6px;
    padding-bottom: 6px;
    transition: background-color 0.3s ease;
}

.menuUserRow:hover
{
    background-color: $inputBgColor;
    transition: background-color 0.3s ease;
}

.btn
{
    background-color: #0F3339;
    width: 30px !important;
    height: 26px;
    padding: 0;
    margin: 0;
    margin-left: 10px;
    margin-top: -8px;
}

.dd-title
{
    font-size: 10px;
    font-family: $fonts;
    font-weight: $regular;
    color: #B7C3DB;
}

.menuUserRow p
{
    font-size: 12px;
    font-weight: $semiBold;
    color: white;
    padding: 0;
    margin: 0;
}
.jobTitle
{
    font-weight: $regular;
    color: #808A9D;
}

.btn i
{
    color: #12CD90;
}
.dropdown
{
    display: inline-block;
}

.dropdown-menu
{
    width: 284px;
    background-color: #32405C;
    border: none;
}

.iconWrapper
{
    background-color: #1C394B;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-radius: 50px;
}

.iconWrapper i
{
    font-size: 32px;
    color: #1B5858;
}

.clickable
{
    cursor: pointer;
}

</style>
