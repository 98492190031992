import { createRouter, createWebHistory } from 'vue-router'
import PageDashboard from '../views/PageDashboard.vue'
//import PageNewReview from '../views/PageNewReview.vue'/
//import PageNewTargets from '../views/PageNewTargets.vue'
//import PageAllReviews from '../views/PageAllReviews.vue'
//import PageReports from '../views/PageReports.vue'
//import PageHelp from '../views/PageHelp.vue'
//import PagePrivacy from '../views/PagePrivacy.vue'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: PageDashboard
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: PageDashboard
  },
  {
    path: '/new-review',
    name: 'New-Review',
    //component: PageNewReview 
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "new-review" */ '../views/PageNewReview.vue')
  },
  {
    path: '/new-targets',
    name: 'New-Targets',
    //component: PageNewTargets
    component: () => import(/* webpackChunkName: "new-targets" */ '../views/PageNewTargets.vue')
  },
  {
    path: '/all-reviews',
    name: 'All-Reviews',
    //component: PageAllReviews
    component: () => import(/* webpackChunkName: "all-reviews" */ '../views/PageAllReviews.vue')
  },
  {
    path: '/reports',
    name: 'Reports',
    //component: PageReports 
    component: () => import(/* webpackChunkName: "reports" */ '../views/PageReports.vue')
  },
  {
    path: '/help',
    name: 'Help',
    //component: PageHelp
    component: () => import(/* webpackChunkName: "help" */ '../views/PageHelp.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    //component: PagePrivacy
    component: () => import(/* webpackChunkName: "privacy" */ '../views/PagePrivacy.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router;
