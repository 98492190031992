<template>
  <div class="container-fluid">
    
    <transition name="vueFade">
      <div class="mainContainer" v-if="loggedIn">
        <BottomNav @modeToggle="modeToggle()" :darkMode="this.darkMode" @toggleNav="toggleNav()" @logout="logout" @navClick="navClick"></BottomNav>
        <div class="row h100">
          
          <LeftNav ref="leftNav" @modeToggle="modeToggle()" :darkMode="this.darkMode" @toggleNav="toggleNav()" @logout="logout" @navClick="navClick"></LeftNav>

          <!-- Main Content -->
          <div class="col">
            <div class="container-fluid px-0 text-center responsiveFluid" style="overflow-x: hidden; overflow-y: auto;">

              <HeaderComponent :firstname="FirstName" :lastname="LastName" :formRepoFilterData="formRepoFilterData" @logout="logout" @navClick="navClick"></HeaderComponent>

              <router-view @navClick="navClick" @startTargetsTour="startTargetsTour" @commitForm="commitForm" @saveForm="saveForm" @resetForm="resetForm" @warnTargets="warnTargets" @warnScores="warnScores"  v-slot="{ Component }">
                <component ref="theView" :is="Component" />
              </router-view>
              
            </div>
          </div>
        </div>
        
        <NewUserTour v-if="this.$store.getters.isFirstReview" @displayScoreInfo="displayScoreInfo"></NewUserTour>
        <TargetsTour ref="targetsTour"></TargetsTour>

      </div>
    </transition>
    
    <transition name="vueFade">
      <!-- Login Screens -->
      <LoginScreen v-if="!loggedIn" @login="login" :loggingIn="this.loggingIn" :landingVideo="landingVideo" ref="loginScreen"></LoginScreen>
    </transition>

    <div class="myModal" v-if="showCommitFormModal">
      <div class="innerContent">
        <img src="./assets/img/alert.svg" class="img-fluid" />
        <h1 class="mt-4 mb-4">{{ $t("Are you sure you’re ready to submit this review?")}}</h1>
        <p class="mb-4">{{ get_submission_text() }}</p>

        <div class="row" style="width: 100%; justify-content: center;">
          <div class="col-4">
            <button class="unfilled" @click="this.commitFormToServer()">{{ $t("Submit")}} <i class="bi bi-check2-circle"></i></button>
          </div>
          <div class="col-4">
            <button class="filled" @click="this.showCommitFormModal = false;">{{ $t("Close")}}</button>
          </div>
        </div>
      </div>
    </div>


    <div class="myModal" v-if="showResetFormModal">
      <div class="innerContent">
        <img src="./assets/img/alert.svg" class="img-fluid" />
        <h1 class="mt-4 mb-4">{{ $t("Are you sure you want to reset?")}}</h1>
        <p class="mb-4">{{ $t("This will reset all of the scores you have entered for the current review") }}</p>

        <div class="row" style="width: 100%; justify-content: center;">
          <div class="col-4">
            <button class="unfilled" @click="this.resetCurrentForm()">{{ $t("Submit")}} <i class="bi bi-check2-circle"></i></button>
          </div>
          <div class="col-4">
            <button class="filled" @click="this.showResetFormModal = false">{{ $t("Close")}}</button>
          </div>
        </div>
      </div>
    </div>


    <div class="myModal" v-if="showTargetsWarningModal">
      <div class="innerContent">
        <img src="./assets/img/alert.svg" class="img-fluid" />
        <h1 class="mt-4 mb-4">{{ $t("You have only added").replace("#", this.targetTotal) }}</h1>
        <p class="mb-4">{{ $t("Close this window to add more").replace("#", this.targetAim - this.targetTotal) }}</p>

        <div class="row" style="width: 100%; justify-content: center;">
          <!--<div class="col-4">
            <button class="unfilled">{{ $t("Submit")}} <i class="bi bi-check2-circle"></i></button>
          </div>-->
          <div class="col-4">
            <button class="filled" @click="showTargetsWarningModal=false">{{ $t("Close")}}</button>
          </div>
        </div>
      </div>
    </div>


    <div class="myModal" v-if="showScoresWarning">
      <div class="innerContent">
        <img src="./assets/img/alert.svg" class="img-fluid" />
        <h1 class="mt-4 mb-4">You must complete all scores to submit</h1>
        
        <div class="row" style="width: 100%; justify-content: center;">
          <!--<div class="col-4">
            <button class="unfilled">{{ $t("Submit")}} <i class="bi bi-check2-circle"></i></button>
          </div>-->
          <div class="col-4">
            <button class="filled" @click="showScoresWarning=false">{{ $t("Close")}}</button>
          </div>
        </div>
      </div>
    </div>

    

  </div>
</template>

<style lang="scss">
@import '@/assets/css/bootstrap.scss';
@import '@/assets/css/variables.scss';
@import '@/assets/css/style.scss';
@import '@/assets/css/responsive.scss';

.myModal
{
  width: 100%;
  height: 100%;
  background-color: #10182bf0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
}

.myModal button
{
  font-weight: $medium;
  max-height: 40px;
}
.myModal button.filled
{
  border: 1px solid white;
}
.myModal button.unfilled
{
  background-color: transparent;
  border: 1px solid $bright-green;
  color: $bright-green;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.innerContent img
{
  max-width: 84px;
}
.innerContent
{
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 562px;
  flex-direction: column;
  background-color: #32405C;
  padding: 30px;
  border-radius: 4px;
}

.innerContent h1, .innerContent p
{
  text-align: center;
}

.innerContent h1
{
  color: white;
  font-size: 16px;
  font-family: $fonts;
  font-weight: $semiBold;
}

.innerContent p
{
  color: #B7C3DB;
  font-size: 14px;
  font-family: $fonts;
}
</style>

<script>
import LeftNav from './components/LeftNav.vue';
import BottomNav from './components/BottomNav.vue';
import HeaderComponent from './components/HeaderComponent.vue';
import LoginScreen from './components/LoginScreen.vue';

import axios from 'axios'

import NewUserTour from './components/NewUserTour.vue';
import TargetsTour from './components/TargetsTour.vue';

import helpers from '@/helpers/helpers';

export default {
  name: 'App',
  mixins: [helpers],
  components: {
    LeftNav,
    BottomNav,
    HeaderComponent,
    LoginScreen,
    NewUserTour,
    TargetsTour
  },
  mounted()
  {
    this.updateSliders();
    axios.defaults.headers.common['X-API-KEY'] = "aSmLiemcs1rZ9hBu1XFEw";
    this.$store.commit('setKivernoAPIEndPoint', this.kivernoAPIEndPoint);
    
    var old_token = localStorage.getItem('kiverno_token');
    
    if (old_token)
    {
      this.bearerToken = old_token;
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.bearerToken}`;
      this.doInitialAuth();
    }

    axios.get(this.kivernoAPIEndPoint + 'getVideo').then((response) => 
    {
      this.landingVideo = response.data.base_url + response.data.landing_page_video;
      this.$refs.loginScreen.loadVideo();
    }).catch((error) => console.log(error));
  },
  data()
  {
    return {
      //kivernoAPIEndPoint: "https://kiverno.devarea.io/backend/api/",
      kivernoAPIEndPoint: "https://www.kiverno.com/backend/api/",
      darkMode: true,
      loggedIn: false,
      currentPageIndex: 0,
      scoreInfoVisible: false,
      bearerToken: "",
      userId: 0,
      Username: "",
      FirstName: "",
      LastName: "",
      UserAccessRole: "",
      loginUsername: "",
      loginPassword: "",
      landingVideo: "",
      loggingIn: false,
      
      clients: [],
      clientModel: [],
      userRecord: {},
      repoData: [],
      repoHistory: [],
      categoryDescriptions: [],
      formRepoFilterData: {},
      categoryElementByAssessment: [],
      categoryElementDescriptions: [],
      pdpList: [],
      pdpDetails: [],

      chart1Data: [],

      showCommitFormModal: false,
      showResetFormModal: false,
      showTargetsWarningModal: false,
      showScoresWarning: false,
      targetTotal: 0,
      targetAim: 0
    }

  },
  watch: 
  {
    $route (to, from)
    {
      console.log("From: " + from.path + " to: " + to.path);
    }
  },
  methods:
  {
    displayScoreInfo(displayIndex)
    {
      if (displayIndex == 0)
      {
        this.$refs.theView.displayScoreInfo(false);
      }
    },
    get_submission_text()
    {       
      var the_text = this.$t("submission_text");
      the_text = the_text.replace("[time]", new Date().toLocaleTimeString());
      the_text = the_text.replace('[dd/mm/yy]', new Date().toLocaleDateString());
      the_text = the_text.replace('[x]', Math.ceil(this.getDaysTillNextQuarter()));
      the_text = the_text.replace('Q[X]', this.getNextFourQuarters(new Date())[0].q);
      return the_text;
    },
    startTargetsTour()
    {
      this.$refs.targetsTour.startTour();
    },
    logout()
    {
      localStorage.removeItem("kiverno_token");
      window.location.reload();
    },
    login(username, password)
    {
      var formdata = new URLSearchParams();
      this.loginUsername = username;
      this.loginPassword = password;
      formdata.append("email", this.loginUsername);
      formdata.append("password", this.loginPassword);

      axios.post(this.kivernoAPIEndPoint + 'auth', formdata).then((response) => 
      {
        console.log(response);
        if(response.data.jwt)
        {
          this.bearerToken = response.data.jwt;

          axios.defaults.headers.common['Authorization'] = `Bearer ${this.bearerToken}`;
          localStorage.setItem("kiverno_token", this.bearerToken);

          this.doInitialAuth();
        }
      }).catch((error) => 
      {
        console.log(error);
        alert("Login failed - Incorrect username or password");
      }
      );
      
      //  
    },
    warnTargets(totalTargets, targetAim)
    {
      this.targetTotal = totalTargets;
      this.targetAim = targetAim;
      this.showTargetsWarningModal = true;
    },
    warnScores()
    {
      this.showScoresWarning = true;
    },
    resetForm()
    {
      this.showResetFormModal = true;
    },
    resetCurrentForm()
    {
      this.showResetFormModal = false;
      var oldModel = this.$store.state.newUserModel;
        
      for (var a = 0; a < this.$store.state.newUserModel.user_forms.length; a++)
      {
          if (this.$store.state.newUserModel.user_forms[a].id == this.$store.state.selectedForm)
          {
            var currentUserForm = this.$store.state.newUserModel.user_forms[a];
            // Can't overwrite commited forms!
            if (currentUserForm.is_committed)
            {
              alert("Form is already committed and can't be amended");
              return;
            }
            else
            {
              currentUserForm.targets = [];
              currentUserForm.scores = [];
              currentUserForm.notes = [];
              
              oldModel.user_forms[a] = currentUserForm;
              this.$store.commit('setNewUserModel', oldModel);
            }
          }
      }

      this.$refs.theView.resetSubElements();
    },
    commitForm()
    {
      this.showCommitFormModal = true;
    },
    saveForm()
    {
      if (this.$store.state.customUserForm != null)
      {
        //console.log("Committing custom user form! " + this.$store.state.customUserForm.id);

        // eslint-disable-next-line no-unused-vars
        axios.post(this.kivernoAPIEndPoint + 'updateForm/' + this.$store.state.customUserForm.id, {formdata: this.$store.state.customUserForm}).then((response) => 
        {
          //alert("Form data saved!");
          //console.log(response);
        }).catch((error) => console.log(error));
      }
      else
      {
        //console.log("Committing current user form! " + this.$store.state.selectedForm);
        for (var a = 0; a < this.$store.state.newUserModel.user_forms.length; a++)
        {
          if (this.$store.state.newUserModel.user_forms[a].id == this.$store.state.selectedForm)
          {
            // eslint-disable-next-line no-unused-vars
            axios.post(this.kivernoAPIEndPoint + 'updateForm/' + this.$store.state.selectedForm, {formdata: this.$store.state.newUserModel.user_forms[a]}).then((response) => 
            {
              //alert("Form data saved!");
              //console.log(response);
            }).catch((error) => console.log(error));
          }
        }
      }
    },
    commitFormToServer()
    {
      this.showCommitFormModal = false;
      if (this.$store.state.customUserForm != null)
      {
        //console.log("Committing custom user form! " + this.$store.state.customUserForm.id);

        var theForm = this.$store.state.customUserForm;
        theForm.is_committed = true;
        this.$store.commit('setCustomUserForm', theForm);

        // eslint-disable-next-line no-unused-vars
        axios.post(this.kivernoAPIEndPoint + 'updateForm/' + this.$store.state.customUserForm.id, {formdata: this.$store.state.customUserForm}).then((response) => 
        {
          alert("Thank you for completing your KIVERNO training needs analysis. You may now exit KIVERNO.");
          //console.log(response);
        }).catch((error) => console.log(error));
      }
      else
      {
        //console.log("Committing current user form! " + this.$store.state.selectedForm);
        for (var a = 0; a < this.$store.state.newUserModel.user_forms.length; a++)
        {
          if (this.$store.state.newUserModel.user_forms[a].id == this.$store.state.selectedForm)
          {
            var oldModel = this.$store.state.newUserModel;
            var currentForm = this.$store.state.newUserModel.user_forms[a];
            currentForm.is_committed = true;

            oldModel.user_forms[a] = currentForm;
            this.$store.commit('setNewUserModel', oldModel);
            
            // eslint-disable-next-line no-unused-vars
            axios.post(this.kivernoAPIEndPoint + 'updateForm/' + this.$store.state.selectedForm, {formdata: this.$store.state.newUserModel.user_forms[a]}).then((response) => 
            {
              alert("Form data saved!");

              // Redirect to dashboard
              this.$router.push('/dashboard');

              //console.log(response);
            }).catch((error) => console.log(error));
          }
        }
      }
    },
    doInitialAuth()
    {
      this.loggingIn = true;
      /*axios.get(this.kivernoAPIEndPoint + 'getNewForm/123/456').then((response) => 
      {
        console.log("getNewForm response: " + response.data);
      });*/

      axios.get(this.kivernoAPIEndPoint + 'users').then((response) => 
      {
        //alert(response.data.lang);
        this.$store.commit('setKivernoAPIEndPoint', this.kivernoAPIEndPoint);

        response.data.sub_users.unshift({ "id": response.data.id, "username": "Personal", "available_reviews": []});

        this.$store.commit('setNewUserModel', response.data);
        //console.log(this.$store.state.newUserModel.review_methods[0].title);
      
        if (response.data.user_forms != null)
        {
          //console.log("Existing user forms found");

          // Find most recent user form (self-review)
          var userRecord = this.$store.state.newUserModel.user_forms.find(x => x.is_self === true);
          //console.log("User record: " + JSON.stringify(userRecord));
          if (userRecord != null)
          {
            if (userRecord.length > 0)
            {
              this.$store.commit('setSelectedForm', userRecord[0].id);
              this.$store.commit('setSelectedModel', userRecord[0].model_reference);

              // Store latest form & model separately
              this.$store.commit('setLatestReviewFormId', userRecord[0].id);
              this.$store.commit('setLatestReviewModelId', userRecord[0].model_reference);
            }
            else
            {
              this.$store.commit('setSelectedForm', userRecord.id);
              this.$store.commit('setSelectedModel', userRecord.model_reference);

              // Store latest form & model separately
              this.$store.commit('setLatestReviewFormId', userRecord.id);
              this.$store.commit('setLatestReviewModelId', userRecord.model_reference);
            }
            
          }
          else
          {
            alert("No self reviews found!!!")
          }
          
        }
        else
        {
          if (response.data.model_definitions != null)
          {
            alert("No form found, but model found!");
            this.$store.commit('setSelectedModel', response.data.model_definitions[0].model_id);
          }
          else
          {
            //console.log("No models!");
            alert("No models have been assigned to your account. Please contact your administrator.")
          }
        }
        

        this.loggedIn = true;
        //this.$router.push('/dashboard');
        
        // Redirect to new review if this is the first review
        if (this.$store.getters.isFirstReview)
        {
          this.$router.push('/new-review');
        }
        else
        {
          this.$router.replace(this.$route.query.from);
        }
        

      }).catch((error) => {
        console.log(error);
        alert(error.message + " " + error.response.data.error);
        localStorage.removeItem("kiverno_token");
        //window.location.reload();
        // Invalid token - force re-login
      });
    },
    navClick(index)
    {
      this.currentPageIndex = index;
      this.$refs.leftNav.setNav(index);

      if (index == 0)
      {
        this.$router.push('/dashboard');
      }
      if (index == 1)
      {
        this.$router.push('/new-review');
      }
      else if (index == 2)
      {
        if (!this.$store.getters.isFirstReview)
        {
          this.$router.push('/new-targets');
        }
      }
      else if (index == 3)
      {
        this.$router.push('/all-reviews');
      }
      else if (index == 4)
      {
        this.$router.push('/reports');
      }
      else if (index == 5)
      {
        this.$router.push('/help');
      }
      else if (index == 6)
      {
        this.$router.push('/privacy');
      }
    },
    dark() 
    {
      document.querySelector('body').classList.remove('light-mode')
      this.darkMode = true
      this.$emit('dark')
    },
    light() {
      document.querySelector('body').classList.add('light-mode')
      this.darkMode = false
      this.$emit('light')
    },
    toggleScoreInfo()
    {
      this.scoreInfoVisible = !this.scoreInfoVisible;
    },
    modeToggle() {
      if(!this.darkMode || document.querySelector('body').classList.contains('light-mode')) 
      {
          this.dark()
      } 
      else 
      {
          this.light()
      }
    },
    updateSliders()
    {
      for (let e of document.querySelectorAll('input[type="range"].slider-progress')) 
      {
        e.style.setProperty('--value', e.value);
        e.style.setProperty('--min', e.min == '' ? '0' : e.min);
        e.style.setProperty('--max', e.max == '' ? '100' : e.max);
        e.addEventListener('input', () => e.style.setProperty('--value', e.value));
      }
      console.log("Updated sliders");
    },
    
  },
  computed:
  {
    darkDark() {
          return this.darkMode && 'darkmode-toggled'
      }
  }
  
}
</script>