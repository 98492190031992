<template>
    <div class="p-2 component chartBg windChart" :id="chart_id">
        <div v-if="categories != null && categories.length > 0" class="outer_chart_wrapper">
            <div class="row">
                <div class="col-12">
                    <h2>{{ chartTitle }}</h2><!-- {{ is_dashboard }}-->
                    <vue-highcharts  v-if="!is_team_reports && showMainChart"
                                    ref="myChart"
                                    type="chart"
                                    :options="getChartOptions"
                                    :redrawOnUpdate="true"
                                    :oneToOneUpdate="true"
                                    :animateOnUpdate="true"
                                    @updated="onUpdated"/>

                    <vue-highcharts  v-else-if="showTeamChart"
                                    ref="teamChart"
                                    type="chart"
                                    :options="getChartOptionsTeam"
                                    :redrawOnUpdate="true"
                                    :oneToOneUpdate="true"
                                    :animateOnUpdate="true"
                                    @updated="onUpdated"/>
                                </div>
            </div>
                    
            <div class="row big_chart_buttons" style="align-items: left; justify-content: space-between; align-items: center;" v-if="showDetailBtn && !isEnlarged">
                <div class="col-4">
                    <button class="detailBtn" @click="this.$emit('showDetail', 1);">{{ $t("View detail")}}</button>
                </div>

                <div class="col-3">
                    <div class="reportIcons">
                        <div class="reportIcon iconPrint" title="Print" @click="this.export()"></div>
                        <div class="reportIcon iconDownload" title="Download" @click="this.generatePDF()"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
@import '@/assets/css/variables.scss';
</style>

<style>
@import 'https://code.highcharts.com/css/highcharts.css';

.highcharts-plot-background {
	fill: transparent;
}
.highcharts-plot-border {
	stroke-width: 2px;
	stroke: #7cb5ec;
}

.highcharts-background
{
    fill: transparent;
}

.highcharts-plot-border
{
    stroke: none !important;
}


/*#gradient-0 stop {
  stop-color: #10DF99;
}

#gradient-0 stop[offset="0"] {
  stop-opacity: 0;
}

#gradient-0 stop[offset="1"] {
  stop-color: #2E3D50;
}


.highcharts-point
{
    fill-opacity: 1 !important;
    fill: url('#gradient-0') !important;
}*/

.highcharts-series-0 .highcharts-point, .highcharts-color-0
{
    fill: #1D8B73 !important;
    stroke: #1D8B73 !important;
}
.highcharts-series-1 .highcharts-point, .highcharts-color-1
{
    fill: #B94AFF !important;
    stroke: #B94AFF !important;
}
.highcharts-series-2 .highcharts-point, .highcharts-color-2
{
    fill: #10DF99 !important;
    stroke: #10DF99 !important;
}
.highcharts-series-3 .highcharts-point, .highcharts-color-3
{
    fill: #FF935A !important;
    stroke: #FF935A !important;
}
</style>

<script>

import VueHighcharts from 'vue3-highcharts';
import Highcharts from 'highcharts'
import html2pdf from "html2pdf.js";

export default {
    name: 'WindChart',
    components:
    {
        VueHighcharts
    },
    methods:
    {
        generatePDF()
        {
            console.log(this.chart_id);
            var element = document.getElementById(this.chart_id);
            var opt = {
                margin:       0.1,
                filename:     'Chart Export',
                image:        { type: 'jpeg', quality: 0.95 },
                jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }
            };
            html2pdf().set(opt).from(element).toContainer().save();
        },
        // Chart Export functionality
        export()
        {
            this.$refs.myChart.chart.exportChart();
        },
        onUpdated()
        {
            //console.log("Chart Updated");
        },
        getCurrentScoreForElement(element_id)
        {
            // All Reviews
            if (this.is_reports)
            {
                console.log("IsReports!");
                if (this.$store.state.reports_user_id == this.$store.state.newUserModel.id)
                {
                    if (this.selectedForm.scores != null)
                    {
                        for (var m = 0; m < this.selectedForm.scores.length; m++)
                        {
                            //console.log("Z: " + JSON.stringify(this.$store.state.reports_form_data.scores));
                            if (this.selectedForm.scores[m].id == element_id)
                            {
                                return this.selectedForm.scores[m].score;
                            }
                        }
                    }
                    
                }
                else
                {
                    if (this.$store.state.reports_form_data != null)
                    {
                        for (var z = 0; z < this.$store.state.reports_form_data.scores.length; z++)
                        {
                            //console.log("Z: " + JSON.stringify(this.$store.state.reports_form_data.scores));
                            if (this.$store.state.reports_form_data.scores[z].id == element_id)
                            {
                                return this.$store.state.reports_form_data.scores[z].score;
                            }
                        }
                    }
                }
                
                return 0;
            }
            else if (this.is_all_reviews)
            {
                console.log("All reviews!");
                if (this.$store.state.allReviewsSelectedUserForms != null)
                {
                    var myForm = this.$store.state.allReviewsSelectedUserForms.forms.find(item => item.id == this.selectedForm);

                    for (var x = 0; x < myForm.scores.length; x++)
                    {
                        if (myForm.scores[x].id == element_id)
                        {
                            return myForm.scores[x].score;
                        }
                    }
                    return 0;
                }
                return 0;
            }
            // Custom user on dashboard page
            else if (this.is_dashboard)
            {
                console.log("Dashboard!");
                if (this.isCustomUser)
                {
                    if (this.$store.state.dashboardSelectedReportData != null)
                    {
                        //console.log("Get score from custom dash data");
                        for (var e = 0; e < this.$store.state.dashboardSelectedReportData.scores.length; e++)
                        {
                            if (this.$store.state.dashboardSelectedReportData.scores[e].id == element_id)
                            {
                                //console.log("Found score (" + this.$store.state.newUserModel.user_forms[a].scores[b].score + ") for: " + element_id);
                                return this.$store.state.dashboardSelectedReportData.scores[e].score;
                            }
                        }
                    }
                }
                else
                {
                    //console.log("Loading wind chart data for form: " + this.selectedForm);
                    for (var a = 0; a < this.$store.state.newUserModel.user_forms.length; a++)
                    {
                        if (this.$store.state.newUserModel.user_forms[a].id == this.selectedForm)
                        {
                            for (var b = 0; b < this.$store.state.newUserModel.user_forms[a].scores.length; b++)
                            {
                                if (this.$store.state.newUserModel.user_forms[a].scores[b].id == element_id)
                                {
                                    //console.log("Found score (" + this.$store.state.newUserModel.user_forms[a].scores[b].score + ") for: " + element_id);
                                    return this.$store.state.newUserModel.user_forms[a].scores[b].score;
                                }
                            }
                        }
                    }
                }
            }
            else
            {
                console.log("Else");
                for (var o = 0; o < this.$store.state.newUserModel.user_forms.length; o++)
                {
                    if (this.$store.state.newUserModel.user_forms[o].id == this.selectedForm)
                    {
                        for (var n = 0; n < this.$store.state.newUserModel.user_forms[o].scores.length; n++)
                        {
                            if (this.$store.state.newUserModel.user_forms[o].scores[n].id == element_id)
                            {
                                //console.log("Found score (" + this.$store.state.newUserModel.user_forms[a].scores[b].score + ") for: " + element_id);
                                return this.$store.state.newUserModel.user_forms[o].scores[n].score;
                            }
                        }
                    }
                }
            }

            return 0;
        }

    },
    watch: {
        getChartOptionsTeam: {
            handler(newOptions) {
                
                if (newOptions != null && newOptions.series != null)
                {
                    console.log("Wind chart data changed, detected by watcher: " + JSON.stringify(newOptions.series));

                    if (this.is_team_reports)
                    {
                        this.showTeamChart = false;
                        setTimeout(()=>{
                            this.showTeamChart = true;
                        },100);
                    }
                }
                
                /*this.showTeamChart = false;
                setTimeout(()=>{
                    this.showTeamChart = true;
                },100);*/
                //console.log(JSON.stringify(this.$refs.teamChart.chart.series));
            },
        deep: true
        },
        getChartOptions: {
            handler(newOptions) {
                if (newOptions != null && newOptions.series != null)
                {
                    console.log("Wind chart data changed, detected by watcher: " + JSON.stringify(newOptions.series));
                    if (!this.is_team_reports)
                    {
                        this.showMainChart = false;
                        setTimeout(()=>{
                            this.showMainChart = true;
                        },100);
                    }
                }
            },
        deep: true
        },
        reports_filter_company_avg()
        {
            //if (this.$refs.myChart.chart != null && this.$refs.myChart.chart.series[1] != null)
            try
            {
                this.$refs.myChart.chart.series[1].setVisible(this.reports_filter_company_avg);
                this.$refs.myChart.chart.series[1].showInLegend(this.reports_filter_company_avg);
            }
            catch (err) { console.log("Wind data for series 1 empty"); }
                
        },
        reports_filter_company_role_avg()
        {
            //if (this.$refs.myChart.chart != null && this.$refs.myChart.chart.series[2] != null)
            try
            {
                this.$refs.myChart.chart.series[2].setVisible(this.reports_filter_company_role_avg);
                this.$refs.myChart.chart.series[2].showInLegend(this.reports_filter_company_role_avg);
            }
            catch (err) { console.log("Wind data for series 2 empty"); }
        }
    },
    computed:
    {
        reports_filter_company_avg()
        {
            return this.$store.state.reports_filter_company_avg;
        },
        reports_filter_company_role_avg()
        {
            return this.$store.state.reports_filter_company_role_avg;
        },
        getChartOptions()
        {
            //console.log("Get wind chart options!");
            var categories = [];
            var seriesData = [];
            
            if (this.$store.state.reports_all_form_data != null && this.$store.state.reports_all_form_data.average_scores_for_company != null && !this.is_dashboard)
            {
                console.log("Render additional data");

                // Build categories
                if (this.categories == null)
                {
                    return;
                }
                for (var l = 0; l < this.categories.length; l++)
                {
                    categories.push(this.categories[l].title);
                }

                var user_series = this.$store.state.reports_all_form_data.wind_chart_for_user;
                var company_role_avg_series = this.$store.state.reports_all_form_data.wind_chart_for_company;
                var company_avg_series = this.$store.state.reports_all_form_data.wind_chart_for_role;

                var user_graph_data1 = { name: "User", data : user_series, type: 'line', pointPlacement: 'on'};
                var user_graph_data2 = { name: "Company avg.", data : company_avg_series, type: 'line', pointPlacement: 'on', visible: this.$store.state.reports_filter_company_avg, showInLegend: this.$store.state.reports_filter_company_avg};
                var user_graph_data3 = { name: "Company avg. for role", data : company_role_avg_series, type: 'line', pointPlacement: 'on', visible: this.$store.state.reports_filter_company_role_avg, showInLegend:this.$store.state.reports_filter_company_role_avg };

                seriesData.push(user_graph_data1);
                seriesData.push(user_graph_data2);
                seriesData.push(user_graph_data3);

                console.log("*** [Series Data] " + JSON.stringify(seriesData));
            }
            else if (this.categories != null)
            {

                console.log("Not null! " + this.categories.length);
                for (var a = 0; a < this.categories.length; a++)
                {
                    categories.push(this.categories[a].title);
                    var totalScoreInCat = 0;
                    for (var b = 0; b < this.categories[a].elements.length; b++)
                    {
                        totalScoreInCat += this.getCurrentScoreForElement(this.categories[a].elements[b].id);
                    }
                    seriesData.push(totalScoreInCat/this.categories[a].elements.length);
                }
            }
            
            //categories.push("");
            console.log("[Chart Categories] " + JSON.stringify(categories));
             var chartOps;
             
             if (this.$store.state.reports_all_form_data != null && this.$store.state.reports_all_form_data.average_scores_for_company != null && !this.is_dashboard)
             {
                console.log("Here!");
                chartOps = 
                {
                    chart: {
                        polar: true,
                        type: 'column',
                        spacing: [0, 0, 0, 0],
                        margin: [0, 0, 0, 0],
                        parallelCoordinates: true,
                    },
                    exporting: {
                    enabled: false,
                        buttons: {
                            contextButton: {
                            enabled: false
                            }
                        }
                    },
                    credits:
                    {
                        enabled: false
                    },
                    accessibility: {
                        description: 'Graph description'
                    },

                    title: {
                        text: ""
                    },

                    pane: {
                        size: '90%'
                    },

                    xAxis: {
                        categories: categories,
                        tickmarkPlacement: 'on',
                        lineWidth: 1,
                        gridLineWidth: 1,
                        min: 0,
                        max: 5,
                    },

                    yAxis: {
                        /*gridLineInterpolation: 'polygon',*/
                        lineWidth: 1,
                        /*angle: 37,*/
                        min: 0,
                        max: 4,
                        tickAmount: 5,
                        tickmarkPlacement: 'off',
                        showLastLabel: false,
                        labels: {
                            allowOverlap: false
                        }
                    },
                    plotOptions: {
                        series: {
                                stacking: undefined,
                                shadow: true,
                                
                                
                            },
                            column:
                            {
                                
                            }
                        },
                    tooltip: {
                        shared: true,
                        pointFormat: '<span style="color:{series.color}">{series.name}: <b>{point.y:,.2f}</b><br/>'
                    },
                    
                    legend: {
                        enabled: false,
                        align: 'center',
                        verticalAlign: 'bottom',
                        layout: 'vertical',
                        symbolPadding: 0,
                        symbolWidth: 0,
                        symbolHeight: 0,
                        squareSymbol: false,
                        borderWidth: 2,
                        title: {
                        text: 'Values'
                        }
                    },

                    series: seriesData,

                    
                };
             }
             else
             {
                chartOps = 
                {
                    chart: {
                        polar: true,
                        type: 'column',
                        spacing: [0, 0, 0, 0],
                        margin: [0, 0, 0, 0],
                    },
                    exporting: {
                    enabled: false,
                        buttons: {
                            contextButton: {
                            enabled: false
                            }
                        }
                    },
                    credits:
                    {
                        enabled: false
                    },
                    accessibility: {
                        description: 'Graph description'
                    },

                    title: {
                        text: ""
                    },

                    pane: {
                        size: '90%'
                    },

                    xAxis: {
                        categories: categories,
                        tickmarkPlacement: 'off',
                        lineWidth: 1,
                        gridLineWidth: 1,
                        
                    },

                    yAxis: {
                        //gridLineInterpolation: 'polygon',
                        lineWidth: 1,
                        min: 0,
                        max: 5,
                        
                        tickAmount: 5,
                        tickmarkPlacement: 'off',
                        showLastLabel: false,
                        labels: {
                        allowOverlap: true
                        }
                    },
                    plotOptions: {
                        series: {
                                stacking: 'normal',
                                shadow: true,
                                groupPadding: 0,
                                pointPadding: 0
                            }
                        },
                    tooltip: {
                        shared: true,
                        pointFormat: '<span style="color:{series.color}">{series.name}: <b>{point.y:,.2f}</b><br/>'
                    },
                    
                    legend: {
                        align: 'center',
                        verticalAlign: 'bottom',
                        layout: 'vertical',
                        symbolPadding: 0,
                        symbolWidth: 0,
                        symbolHeight: 0,
                        squareSymbol: false,
                        borderWidth: 2,
                        title: {
                        text: 'Values'
                        }
                    },

                    series: [{
                        name: 'Score',
                        data: seriesData,
                        pointPlacement: 'on',
                        showInLegend: false
                    }],

                    
                };
            }
             
            
            console.log("Wind chart!: " + JSON.stringify(chartOps));
            return chartOps;
        },
        getChartOptionsTeam()
        {
            //console.log("Get wind chart options!");
            var categories = [];
            var seriesData = [];
        
            if (this.categories != null)
            {
                //console.log("Not null! " + this.formWithSelectedId.length);
                for (var a = 0; a < this.categories.length; a++)
                {
                    categories.push(this.categories[a].title);
                    /*var totalScoreInCat = 0;
                    for (var b = 0; b < this.categories[a].elements.length; b++)
                    {
                        totalScoreInCat += this.getCurrentScoreForElement(this.categories[a].elements[b].id);
                    }
                    seriesData.push(totalScoreInCat/this.categories[a].elements.length);*/
                }
            }

            if (this.$store.state.team_report_data != null)
            {
                for (var g = 0; g < this.$store.state.team_report_data.categories.length; g++)
                {
                    categories.push(this.$store.state.team_report_data.categories[g].title);
                }
                
                for (var n = 0; n < this.$store.state.team_report_data.selectedTeam.length; n++)
                {
                    var user_graph_data = { name: "" + this.$store.state.team_report_data.series_data[n].name, data : [], type: 'line', pointPlacement: 'between', color: "#ff0000"};
                    var user_data = this.$store.state.team_report_data.series_data[n].data[0];

                    /*for (var f = 0; f < this.$store.state.team_report_data.series_data[0].data.length; f++)
                    {
                        user_data.push(this.$store.state.team_report_data.series_data[0].data[f] + n);
                    }*/
                    user_graph_data.data = user_data;
                    seriesData.push(user_graph_data);
                }
            }
            
             var chartOps = {
                chart: {
                    type: 'column',
                    polar: true,
                    //parallelCoordinates: true,
                    margin: [0, 0, 0, 0],
                    styledMode: true
                },
                exporting: {
                    buttons: {
                        contextButton: {
                        enabled: false
                        }
                    }
                },
                defs: {
                gradient0: {
                    tagName: 'linearGradient',
                    id: 'gradient-0',
                    x1: 0,
                    y1: 0,
                    x2: 0,
                    y2: 1,
                    children: [{
                    tagName: 'stop',
                    offset: 0
                    }, {
                    tagName: 'stop',
                    offset: 1
                    }]
                }
                },
                credits: {
                    enabled: false
                },
                legend:
                {
                    enabled: true
                },
                title: {
                    text: '',
                },
                xAxis: {
                    categories: categories,
                    labels: { enabled: false },
                    tickInterval: 72,
                    min: 0,
                    max: 360
                },
                yAxis: {
                    title: {
                        text: '',
                    },
                    
                    max: 4,
                    min: 0,
                    tickInterval: 1,
                    
                },
                plotOptions:
                {
                    column:
                    {
                        borderRadius: 0,
                        grouping: false,
                        pointPadding: 0,
                        groupPadding: 0
                    },
                    series: {
                        //stacking: 'normal',
                        pointStart: 0,
                        pointInterval: 72
                    },
                         
                },
                tooltip: {
                    valueDecimals: 2,
                    useHTML: true,
                    formatter: function() {
                        return 'The score for <b>' + this.x + '</b> is <b>' + Highcharts.numberFormat(this.y, 2) + '</b>';
                    }
                },
                series:  seriesData,

                pane: {
                    startAngle: 0,
                    endAngle: 360
                },
            };
            
            console.log(JSON.stringify(chartOps));
            return chartOps;
        }
    },
    data () {
        return {
            numNum: Number,
            updateArgs: [true, true, {duration: 1000}],
            chartOptions: {},
            showTeamChart: true,
            showMainChart: true
        }
    },
    props:
    {
        chart_id: String,
        chartData: Array,
        chartTitle: String,
        selectedForm: Number,
        selectedModel: Number,
        categories: Array,
        showDetailBtn: Boolean,
        isCustomUser: Boolean,
        is_all_reviews: Boolean,
        is_reports: Boolean,
        is_team_reports: Boolean,
        is_dashboard: Boolean,
        isEnlarged: Boolean
    }
}
</script>