import 'bootstrap-icons/font/bootstrap-icons.css'
import Highcharts from 'highcharts'
//import HighchartsVue from 'highcharts-vue'
import highchartsMore from 'highcharts/highcharts-more'
import accessibility from "highcharts/modules/accessibility";
import exporting from "highcharts/modules/exporting.js";
import VueHighcharts from "vue-highcharts";
import { createI18n } from "vue-i18n";
import { createStore } from 'vuex'
import { createApp } from 'vue'
import FloatingVue from 'floating-vue'
//import * as VueRouter from 'vue-router'
//import tooltip from "./directives/tooltip.js";
//import "@/assets/css/tooltip.css";
import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/aura-light-green/theme.css'

import App from './App.vue'

import "bootstrap/dist/js/bootstrap.js"
import router from './router'


highchartsMore(Highcharts);
accessibility(Highcharts);
exporting(Highcharts);

import de from "./assets/de.json";
import en from "./assets/en.json";
import es from "./assets/es.json";
import fr from "./assets/fr.json";
import it from "./assets/it.json";
import jp from "./assets/jp.json";
import zhcn from "./assets/zh-cn.json";

import { html2pdf } from "html2pdf.js";

// configure i18n
const i18n = createI18n({
  locale: "en",
  fallbackLocale: "en",
  legacy: false,
  globalInjection: true,
  allowComposition: true,
   useScope: 'global' ,
  messages: { de, en, es, fr, it, jp, zhcn },
});

// configure the store
const store = createStore({
  state() {
    return {

      userId: 0,

      

      
      availableQuarters: [],

      newUserModel: {},
      selectedModel: -1,
      selectedForm: -1,
      latestReviewFormId: -1,
      latestReviewModelId: -1,
      selectedUserForm: -1,
      selectedUserModel: -1,

      // API end point
      kivernoAPIEndPoint: "",
      loadedUserData: {},

      // Custom use form
      customUserForm: null,

      // Dashboard states
      dashboardSelectedUser: null,
      dashboardSelectedForm: null,
      dashboardSelectedReportData: null,
      
      // New reviews states
      newReviewSelectedUser: null,

      // All reviews states
      allReviewsSelectedUser: null,
      allReviewsSelectedUserForms: null,

      // Reports page
      reports_reporting_type: 0,
      reports_model_id: 0,
      reports_user_id: 0,
      reports_form_data: null,
      reports_all_form_data: null,

      // Checkbox filters
      reports_filter_previous: false,
      reports_filter_company_avg: false,
      reports_filter_company_role_avg: false,

      // Reports team data
      team_data: null,
      team_report_data: null,

      // Registration data
      registration_data: null,

      firstReviewDate: Date,
      lastReviewDate: Date
    }
  },
  getters:
  {
    isFirstReview(state)
    {
      if (state.newUserModel.user_forms.length == 1)
      {
        if (state.newUserModel.user_forms[0].is_self && state.newUserModel.user_forms[0].is_committed)
        {
          return false;
        }
        return true;
      }

      if (state.newUserModel.user_forms.length > 1)
      {
        var totalReviews = 0;
        for (var s = 0; s < state.newUserModel.user_forms.length; s++)
        {
          if (state.newUserModel.user_forms[s].is_self)
          {
            totalReviews++;
          }
        }

        if (totalReviews > 1)
        {
          return true;
        }
      }

      return false;
    },
    showTargetsTour(state)
    {
      if (state.newUserModel.user_forms.length == 1)
      {
        for (var n = 0; n < state.newUserModel.user_forms[0].targets.length; n++)
        {
          if (state.newUserModel.user_forms[0].targets[n].id != 0 && state.newUserModel.user_forms[0].targets[n].target_value != "0" && state.newUserModel.user_forms[0].targets[n].target_value != 0)
          {
            return false;
          }        
        }
        return true;
      }
    }
  },
  computed:
  {
    getFirstReviewDate()
    {
      return this.firstReviewDate;
    },
  },
  mutations: 
  {
    set_registration_data(state, payload)
    {
      state.registration_data = payload;
    },
    set_reports_filter_previous(state, payload)
    {
      state.reports_filter_previous = payload;
    },
    set_reports_filter_company_avg(state, payload)
    {
      state.reports_filter_company_avg = payload;
    },
    set_reports_filter_company_role_avg(state, payload)
    {
      state.reports_filter_company_role_avg = payload;
    },
    set_team_report_data(state, payload)
    {
      state.team_report_data = payload;
    },
    set_team_data(state, payload)
    {
      state.team_data = payload;
    },
    set_reports_form_data(state, payload)
    {
      state.reports_form_data = payload;
    },
    set_reports_all_form_data(state, payload)
    {
      state.reports_all_form_data = payload;
    },
    set_reports_reporting_type(state, payload)
    {
      state.reports_reporting_type = payload;
    },
    set_reports_model_id(state, payload)
    {
      state.reports_model_id = payload;
    },
    set_reports_user_id(state, payload)
    {
      state.reports_user_id = payload;
    },
    setAllReviewsSelectedUserForms(state, payload)
    {
      console.log("Set All Reviews User forms to: " + payload);
      state.allReviewsSelectedUserForms = payload;
    },
    setAllReviewsSelectedUser(state, payload)
    {
      console.log("Set All Reviews User to: " + payload);
      state.allReviewsSelectedUser = payload;
    },
    setNewReviewSelectedUser(state, payload)
    {
      state.newReviewSelectedUser = payload;
    },
    setDashboardSelectedReportData(state, payload)
    {
      //console.log("Save dash report data to state: " + JSON.stringify(payload));
      state.dashboardSelectedReportData = payload;
    },
    setDashboardSelectedForm(state, payload)
    {
      //console.log("Save dash form to state: " + JSON.stringify(payload));
      state.dashboardSelectedForm = payload;
    },
    setDashboardSelectedUser(state, payload)
    {
      state.dashboardSelectedUser = payload;
    },
    setCustomUserForm(state, payload)
    {
      state.customUserForm = payload;
      //console.log("Set state for customUserForm: " + JSON.stringify(state.customUserForm));
    },
    setLoadedUserData(state, payload)
    {
      state.loadedUserData = payload;
    },
    setKivernoAPIEndPoint(state, payload)
    {
      state.kivernoAPIEndPoint = payload;
    },
    setLatestReviewFormId(state, payload)
    {
      state.latestReviewFormId = payload;
    },
    setLatestReviewModelId(state, payload)
    {
      state.latestReviewModelId = payload;
    },
    setNewUserModel(state, payload)
    {
      state.newUserModel = payload;
    },
    setSelectedModel(state, payload)
    {
      state.selectedModel = payload;
      //console.log("Set selected model to: " + state.selectedModel);
    },
    setSelectedForm(state, payload)
    {
      state.selectedForm = payload;
      //console.log("Set selected form to: " + state.selectedForm);
    },

    setSelectedUserModel(state, payload)
    {
      state.selectedUserModel = payload;
      //console.log("Set selected other user model to: " + state.selectedUserModel);
    },
    setSelectedUserForm(state, payload)
    {
      state.selectedUserForm = payload;
      //console.log("Set selected other user form to: " + state.selectedUserForm);
    },

    setUserId(state, payload)
    {
      this.userId = payload;
    },
    setUsername(state, payload)
    {
      this.Username = payload;
    },
    setFirstName(state, payload)
    {
      this.FirstName = payload;
    },
    setLastName(state, payload)
    {
      this.LastName = payload;
    },
    setUserAccessRole(state, payload)
    {
      this.UserAccessRole = payload;
    },
    setClients(state, payload)
    {
      this.clients = payload;
    },
    setReviewMethods(state, payload)
    {
      this.reviewMethods = payload;
    },
    setScoreboardData(state, payload)
    {
      this.scoreboardData = payload;
    },
    setAllClients(state, payload)
    {
      this.allClients = payload;
    },
    setClientModel(state, payload)
    {
      this.clientModel = payload;
    },
    setUserRecord(state, payload)
    {
      this.userRecord = payload;
    }
  }
})


const app = createApp(App).use(router);
router.isReady().then(() => 
{
  app.use(store).use(VueHighcharts, { Highcharts }).use(html2pdf).use(FloatingVue).use(i18n).use(PrimeVue).mount('#app')
});

