<template>
    <div class="p-2 component">
      <div class="row mt-2">
        <div class="col-6">
          <h2>{{ $t("My reports")}}</h2>
        </div>
        <div class="col-6">
          <div class="row smallButtonRow">
            <div class="col-5 smallButton">
              <button class="" @click="this.$emit('navClick', 4)">{{ $t("All reports")}}</button>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <QuartersDropDown :available_quarters="getAvailableQuarters" @selectQuarter="selectQuarter"></QuartersDropDown>
        </div>
      </div>

      <div class="row mt-3 justify-content-between">
        <div class="col-6">
          <AvailableCharts :available_charts="getAvailableCharts" @selectChart="selectChart"></AvailableCharts>
        </div>
        <!--<div class="col-6">
          <div class="reportIcons">
            <div class="reportIcon iconPrint" title="Print"></div>
            <div class="reportIcon iconDownload" title="Download" @click="generatePDF()"></div>
          </div>
        </div>-->
      </div>

      <div class="row justify-content-center mt-3">
        <div class="col-12">
            <div v-if="formWithSelectedId() != null">
              <WindChart id="chart0" 
                        chart_id="chart0" 
                        :is_dashboard="true" 
                        :isCustomUser="isCustomUser" 
                        @showDetail="showDetail" 
                        :selectedModel="selectedModel" 
                        :selectedForm="selectedForm" 
                        :categories="formWithSelectedId()" 
                        v-if="selectedChart == 0" 
                        chartTitle="Development Profile"></WindChart>
              
              <div v-for="(item, index) in formWithSelectedId()" :key="index" >
                <BarChart :id="'chart' + (index+1)" 
                :chart_id="'my-reports'+index"
                        :is_dashboard="true" 
                        :isCustomUser="isCustomUser" 
                        @showDetail="showDetail" 
                        :selectedModel="selectedModel" 
                        :selectedForm="selectedForm" 
                        v-if="selectedChart == (index+1)" 
                        :ref="'chart' + (index+1) + 'l'" 
                        :chartIndex="index + 2" 
                        :catId="formWithSelectedId()[index].id" 
                        :chartTitle="formWithSelectedId()[index].title"></BarChart>
              </div>
            </div>
            <div v-else>
              <p>Data for this user in this quarter is not available.</p>
            </div>
          </div>
      </div>

      <!--<div class="row">
        <div class="col-12">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault1">
            <label class="form-check-label" for="flexCheckDefault1">
              {{ $t("My previous score")}}
            </label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault2">
            <label class="form-check-label" for="flexCheckDefault2">
              {{ $t("My company average")}}
            </label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault3">
            <label class="form-check-label" for="flexCheckDefault3">
              {{ $t("Company average for my role")}}
            </label>
          </div>
        </div>
      </div>-->

    </div>
</template>

<script>

import AvailableCharts from "./AvailableCharts.vue";
import QuartersDropDown from "./QuartersDropDown.vue";
import WindChart from "./WindChart.vue";
import BarChart from "./BarChart.vue";
import axios from 'axios'
import html2pdf from "html2pdf.js";

export default {
    name: 'MyReports',
    components:
    {
        AvailableCharts,
        WindChart,
        BarChart,
        QuartersDropDown,
    },
    methods:
    {
        showDetail()
        {
          console.log("*** Show detail");
        },
        generatePDF()
        {
          var element = document.getElementById('chart' + this.selectedChart);
          html2pdf(element);//.toContainer().save();
          //this.$refs.html2Pdf.generatePdf();
        },
        selectQuarter(quarter)
        {
          this.selectedQuarter = quarter;
          //console.log("My reports - selected quarter: " + quarter);
        },
        selectChart(index)
        {
          //console.log("Select chart: " + index);
          this.selectedChart = index;
        },
        getNewFormData(formId)
        {
          console.log("Load report data for: " + formId);
          
          if (this.$store.state.dashboardSelectedReportData == {} || this.$store.state.dashboardSelectedReportData == null || this.$store.state.dashboardSelectedReportData.id != formId)
          {
            axios.get(this.$store.state.kivernoAPIEndPoint + 'getForm/' + formId).then((response) => 
            {
              //console.log("getNewForm response: " + response.data);
              this.$store.commit('setDashboardSelectedReportData', response.data);
            });
          }
        },
        formWithSelectedId()
        {
          console.log("Find form for quarter: " + this.selectedQuarter);

          // We aren't displaying data for current user, but for a sub user
          if (this.$store.state.dashboardSelectedUser != null)
          {
            console.log("Loading custom user chart for dashboard for user: " + this.$store.state.dashboardSelectedUser);
            this.isCustomUser = true;
            //console.log("Get report data for custom user! " + this.$store.state.dashboardSelectedUser);
            var theUser = this.$store.state.newUserModel.sub_users.filter(item => (item.id == this.$store.state.dashboardSelectedUser))[0];
            //console.log("The user: " + JSON.stringify(theUser));
            var theForm = theUser.available_reviews.filter(item => (item.quarter == this.selectedQuarter && item.is_self))[0];
            //console.log("Forms length: " + JSON.stringify(theForm));
            if (theForm == undefined)
            {
              return null;
            }
            this.getNewFormData(theForm.id);
            this.selectedModel = theForm.model_reference;
            return this.$store.state.newUserModel.model_definitions.find(x => x.model_id === theForm.model_reference).categories;
          }
          else
          {
            //console.log("Loading current user charts!")
            this.isCustomUser = false;
            var theForms = this.$store.state.newUserModel.user_forms.filter(item => (item.quarter == this.selectedQuarter && item.is_self));
            //console.log("Forms length: " + theForms.length);
            for (var a = 0; a < theForms.length; a++)
            {
              //console.log("Found form: " + theForms[a].id);
              //console.log("Model for form: " + theForms[a].model_reference);
              this.selectedModel = theForms[a].model_reference;
              this.selectedForm = theForms[a].id;

              //console.log("Available categories: " + this.$store.state.newUserModel.model_definitions.find(x => x.model_id === theForms[a].model_reference).categories);
              return this.$store.state.newUserModel.model_definitions.find(x => x.model_id === theForms[a].model_reference).categories;
            }
          }
          
          
          
          return null;
          
        },
    },
    data () {
        return {
            selectedQuarter: "",
            selectedChart: 0,
            selectedModel: 0,
            selectedForm: 0,
            isCustomUser: false
        }
    },
    computed:
    {
      
      getAvailableCharts()
      {
        var available_charts = [];
        //console.log("Updating available charts for " + this.selectedQuarter);
        if (this.selectedQuarter != "")
        {
          var form;
          if (this.$store.state.dashboardSelectedUser != null)
          {
            form = this.$store.state.dashboardSelectedReportData;
          }
          else
          {
            form = this.$store.state.newUserModel.user_forms.find(item => (item.quarter == this.selectedQuarter && item.is_self));
          }

          //console.log("Getting available charts for " + form.id);
          //console.log("Model reference: " + form.model_reference);
          if (form != null)
          {
            var cats = this.$store.state.newUserModel.model_definitions.find(x => x.model_id === form.model_reference).categories;
            //console.log("Cats length: " + cats.length);
            available_charts.push({ "id": 0, "title": "Development profile" });
            for (var a = 0; a < cats.length; a++)
            {
              available_charts.push({ "id": cats[a].id, "title": cats[a].title });
            }
          }
        }

        //console.log("Available charts: " + JSON.stringify(available_charts));
        return available_charts;
        
      },
      getAvailableQuarters()
      {
        var available = [];

        for (var a = 0; a < this.$store.state.newUserModel.user_forms.length; a++)
        {
          if (this.$store.state.newUserModel.user_forms[a].quarter != "" && this.$store.state.newUserModel.user_forms[a].is_self)
          {
            available.push({ "q": this.$store.state.newUserModel.user_forms[a].quarter, "d": this.$store.state.newUserModel.user_forms[a].date_of_review });
          }
        }

        return available;
      }
    },
    props:
    {
        
    }
}
</script>