<template>
<!-- Registration Form -->
<div class="col-10">
    <div class="row">
      <div class="col-12">
        <h1>{{$t('Welcome to Kiverno')}}</h1>
      </div>

      <div class="row">
        <div class="col-12">
          <p>{{$t('Reset your password below')}}</p>
        </div>
      </div>


      <div class="row mt-3">
        <div class="col-6">
          <label>{{$t('Create password')}}</label>
        </div>
        <div class="col-6">
          <label>{{$t('Confirm password')}}</label>
        </div>
      </div>

      <div class="row" >
        <div class="col-6">
          <input type="password" id="password" name="password" v-model="password.password" placeholder="Create password" autocomplete="new-password" />
          <span class="error" v-if="v$.password.password.$error"> {{ v$.password.password.$errors[0].$message }} </span>
        </div>
        <div class="col-6">
          <input type="password" id="password2" name="password2" v-model="password.confirm" placeholder="Confirm password" autocomplete="new-password"/>
          <span class="error" v-if="v$.password.confirm.$error"> {{ v$.password.confirm.$errors[0].$message }} </span>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <button @click="submitForm">{{$t('Reset')}}</button>
        </div>
      </div>
      <p v-for="error of v$.$errors" :key="error.$uid"><strong>{{ error.$validator }}</strong>
<small> on property</small>
<strong>{{ error.$property }}</strong>
<small> says:</small>
<strong>{{ error.$message }}</strong>
</p>
    

    </div>
  </div>
</template>

<style>
.error
{
  color: red;
  font-size: 12px;
}
</style>

<script>

import useValidate from '@vuelidate/core'
import { required, minLength, sameAs } from '@vuelidate/validators'

export default {
    name: 'RegistrationForm',
    components:
    {
      
    },
    methods:
    {
      submitForm() {
        this.v$.$validate();
        if (!this.v$.$error) 
        {
            this.$emit('resetPassword', this.password.password);
        } 
        else 
        {
          console.log('Form failed validation ' + JSON.stringify(this.v$));
        }
      }
    },
    data () {
        return {
          v$: useValidate(),
          emailAddress: "",
          password:
          {
            password: "",
            confirm: ""
          },
          errorMsg: "This domain does not exists with any client please enter valid domain.",
        }
    },
    validations()
    {
      return {
        password:
        {
          password: { required, minLength: minLength(6) },
          confirm: { required, sameAsPassword: sameAs(this.password.password) }
        }
      }
    },
    props:
    {
      
    }
}
</script>