<template>
    
    <div class="p-2 component help" @click="this.$emit('navClick', 5)">
        <div class="helpInner">
            <h1>{{ $t("Help & assistance")}}<br /><i class="bi bi-arrow-right"></i></h1>
        </div>
    </div>
</template>

<style>

.help
{
    cursor: pointer;
}

</style>