<template>
    <div class="dropdown kivernoDropdown" v-if="available_quarters != null && available_quarters.length > 0">
        <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        {{ available_quarters[selectedIndex].q }}
        <!-- this.$store.state.newUserModel.available_quarters[selectedIndex] -->
        </button>
        <ul class="dropdown-menu">
            <li v-for="(dropdownItem, index) in available_quarters" :key="index"><a @click="selectItem(index)" class="dropdown-item" href="#">{{ dropdownItem.q }}</a></li>
        </ul>
    </div>
</template>


<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

.kivernoDropdown
{
    border: 1px solid $bright-green;
    border-radius: 4px;
}

</style>

<script>
import helpers from '@/helpers/helpers'

export default {
    mixins: [helpers],
    name: 'QuartersDropDown',
    components:
    {
        
    },
    mounted()
    {
        //console.log("*** Mounted QuartersDropDown with: " + JSON.stringify(this.available_quarters));
        if (this.available_quarters != undefined)
        {
            this.selectItem(0);
        }
    },
    methods:
    {
        selectItem(index)
        {
            this.selectedIndex = index;
            //console.log("Selecting quarter at index: " + this.selectedIndex);
            if (this.available_quarters != null && this.available_quarters.length > 0)
            {
                this.$emit('selectQuarter', this.available_quarters[this.selectedIndex].q);
            }
            
        }
    },
    data () {
        return {
            selectedIndex: 0
        }
    },
    props:
    {
        available_quarters: Array
    }
}
</script>