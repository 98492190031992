<template>
    <div class="dropdown kivernoDropdown" v-if="dropDownItems != null && dropDownItems != []">
        <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        {{ dropDownItems[this.selectedItem] == null ? "" : $t(dropDownItems[this.selectedItem].title) }}
        </button>
        <ul class="dropdown-menu">
            <li v-for="(dropdownItem, index) in dropDownItems" :key="index"><a class="dropdown-item" href="#" @click="selectItem(index)">{{ $t(dropdownItem.title) }}</a></li>
        </ul>
    </div>
</template>


<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

.kivernoDropdown
{
    border: 1px solid $bright-green;
    border-radius: 4px;
    height: 100%;
}

.kivernoDropdown button
{
    height: 100%;
}

</style>

<script>
//import { computed } from 'vue'

export default {
    name: 'CustomDropDown',
    components:
    {
        
    },
    /*setup(props, { emit }) { 
        const message = computed({ 
        get: () => props.modelValue, 
        set: (value) => emit('update:modelValue', value) 
        }) 

        return { 
        message,
        } 
    },*/
    mounted()
    {
        if (this.dropDownItems != null && this.dropDownItems != [])
        {
            //alert("Mounted: " + this.selectedItem);
            if (this.initialValue != null)
            {
                this.selectItem(this.initialValue);
            }
            else
            {
                this.selectItem(this.selectedItem);
            }
            
        }
    },  
    methods:
    {
        selectItem(index)
        {
            if (this.dropDownItems != null && this.dropDownItems != [])
            {
                this.selectedItem = index;
                this.$emit('selectItem', this.dropDownItems[this.selectedItem].id);
            }
            
        }
    },
    data () {
        return {
            selectedItem: 0
        }
    },
    props:
    {
        dropDownItems: Array,
        initialValue: Number
    }
}
</script>