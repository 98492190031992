<template>
    <!-- Bottom Nav -->
    <div class="bottomNav d-xl-none d-flex">
        <div class="col" @click="navClick(0)">
            <i class="bi bi-grid navIcon"></i>
            <span>{{ $t("Dashboard")}}</span>
        </div>
         <div class="col" @click="navClick(1)">
            <i class="bi bi-plus-circle navIcon"></i>
            <span>{{ $t("New review")}}</span>
        </div>
        <div class="col" @click="navClick(2)" :class="this.$store.getters.isFirstReview ? 'greyedOut' : ''">
            <i class="bi bi-crosshair navIcon"></i>
            <span>{{ $t("My targets")}}</span>
        </div>
        <div class="col" @click="navClick(3)">
            <i class="bi bi-stack navIcon"></i>
            <span>{{ $t("All reviews")}}</span>
        </div>
        <div class="col" @click="navClick(4)">
            <i class="bi bi-pie-chart navIcon"></i>
            <span>{{ $t("Reports")}}</span>
        </div>
    </div>
    <!-- End Bottom Nav -->
</template>

<script>
//import LocaleChanger from "@/components/LocaleChanger.vue";

export default {
    name: 'BottomNav',
    components:
    {
        //LocaleChanger
    },
    methods:
    {
        modeToggle()
        {
            this.$emit('modeToggle');
        },
        toggleNav()
        {
            this.navToggle = !this.navToggle;
        },
        getNavClass()
        {
            if (this.navToggle)
            {
                return "active";
            }
            else
            {
                return "";
            }
        },
        navClick(index)
        {
            this.$emit('navClick', index);
            this.currentNav = index;
        }, 
        setNav(index)
        {
            this.currentNav = index;
        }
    },
    data()
    {
        return {
            navToggle: true,
            currentNav: 0
        }
    },
    props:
    {
        darkMode: Boolean
    }
}
</script>