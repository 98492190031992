<template>
    <div>
      <VOnboardingWrapper ref="wrapper" :steps="steps" />
    </div>
  </template>
  
  <script>
  import { defineComponent, ref, onMounted, defineExpose } from 'vue'
  import { VOnboardingWrapper, useVOnboarding } from 'v-onboarding'
  import 'v-onboarding/dist/style.css'
  import { useI18n } from "vue-i18n";
  import { useStore } from 'vuex'

  export default defineComponent ({
    components: {
      VOnboardingWrapper
    },
    setup() {
      const wrapper = ref(null)
      const { start, goToStep, finish } = useVOnboarding(wrapper)

      const store = useStore()
      const {t, locale} = useI18n()

      if (store.state.newUserModel.lang == "spanish")
      {
        locale.value = "es";
      }
      if (store.state.newUserModel.lang == "french")
      {
        locale.value = "fr";
      }
      if (store.state.newUserModel.lang == "italian")
      {
        locale.value = "it";
      }
      if (store.state.newUserModel.lang == "german")
      {
        locale.value = "de";
      }
      if (store.state.newUserModel.lang == "japanese")
      {
        locale.value = "jp";
      }

      const steps = [
        {
          attachTo: { element: '#customRange1' },
          content: 
          { 
            description: t('tour2a'),
            html: true,
          },
          options:
          {
            scrollToStep: {
                enabled: true,
                options: {
                  behavior: 'smooth',
                  block: 'center',
                  inline: 'end'
                }
            },
          }
        },
        {
          attachTo: { element: '.quarterBtn' },
          content: 
          { 
            description: t('tour2b'),
            html: true,
          },
          options:
          {
            scrollToStep: {
                enabled: true,
                options: {
                  behavior: 'smooth',
                  block: 'center',
                  inline: 'end'
                }
            },
          }
        },
        
        
      ]
    
      // eslint-disable-next-line
      const startTour = () =>
      {
        setTimeout(()=>{
            start();
        },1000);
        
      };

      onMounted(() => {
        //start()
      })
      
      defineExpose({
        startTour
        });

      return {
        wrapper,
        steps,
        start,
        finish,
        goToStep,
        startTour
      }

      
    }
  })
  </script>