<template>
    <!-- Dashboard -->
    <div class="row gx-3 dashRow">
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 dashCol">
            <MyReview @navClick="navClick"></MyReview>
            <MyReports @navClick="navClick"></MyReports>
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 dashCol">
            <!-- My Targets -->
            <HelpAssistance @navClick="navClick"></HelpAssistance>
            <MyTargets @navClick="navClick"></MyTargets>
            <CompletedReviews :componentType="2" @navClick="navClick"></CompletedReviews>
            <CompletedReviews :componentType="1" @navClick="navClick"></CompletedReviews>
            <CompletedReviews :componentType="0" @navClick="navClick"></CompletedReviews>
        </div>
    </div>
</template>

<script>

import MyReview from "../components/MyReview.vue"
import MyTargets from "../components/MyTargets.vue"
import MyReports from "../components/MyReports.vue"
import HelpAssistance from "../components/HelpAssistance.vue"
import CompletedReviews from "../components/CompletedReviews.vue"

export default {
    name: 'PageDashboard',
    components:
    {
        MyReview,
        MyTargets,
        MyReports,
        HelpAssistance,
        CompletedReviews
    },
    methods:
    {
      toggleScoreInfo()
      {
        this.$emit('toggleScoreInfo');
      },
      navClick(index)
      {
        this.$emit('navClick', index);
      }
    },
    data () {
        return {
          
        }
    },
    props:
    {
        
    }
}
</script>