<script setup lang="ts"/>

<template>
  <div class="locale-changer mb-3 mt-3">
    <select v-model="$i18n.locale" @change="updateLang()">
      <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang.code">
        {{ $t(lang.text) }}
      </option>
    </select>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "locale-changer",
  mounted()
  {
    this.setUserLanguage();
  },
  methods:
  {
    updateLang()
    {
      var currentLang = "";

      if (this.$i18n.locale == "es")
      {
        currentLang = "spanish";
      }
      else if (this.$i18n.locale == "fr")
      {
        currentLang = "french";
      }
      else if (this.$i18n.locale == "it")
      {
        currentLang = "italian";
      }
      else if (this.$i18n.locale == "de")
      {
        currentLang = "german";
      }
      else if (this.$i18n.locale == "jp")
      {
        currentLang = "japanese";
      }
      else if (this.$i18n.locale == "zh-cn")
      {
        currentLang = "mandarin-simple";
      }
      else
      {
        currentLang = "English";
      }
      
      if (this.$store.state.newUserModel == undefined || this.$store.state.newUserModel.id == undefined)
      {
        return;
      }

      axios.post(this.$store.state.kivernoAPIEndPoint + 'setUserLanguage/1', {lang: currentLang}).then((response) => 
      {
        console.log("setUserLanguage response: " + response.data);

        if (confirm(this.$t('Lang_change'))) 
        {
        // Save it!
          location.reload();
        } else {
        // Do nothing!
        //console.log('Thing was not saved to the database.');
        }

      });
    },
    setUserLanguage()
    {
      if (this.$store.state.newUserModel != null)
      {
        if (this.$store.state.newUserModel.lang != null)
        {
          if (this.$store.state.newUserModel.lang == "default")
          {
            this.$i18n.locale = "en";
          }
          if (this.$store.state.newUserModel.lang == "spanish")
          {
            this.$i18n.locale = "es";
          }
          if (this.$store.state.newUserModel.lang == "french")
          {
            this.$i18n.locale = "fr";
          }
          if (this.$store.state.newUserModel.lang == "italian")
          {
            this.$i18n.locale = "it";
          }
          if (this.$store.state.newUserModel.lang == "german")
          {
            this.$i18n.locale = "de";
          }
          if (this.$store.state.newUserModel.lang == "japanese")
          {
            this.$i18n.locale = "jp";
          }
          if (this.$store.state.newUserModel.lang == "mandarin-simple")
          {
            this.$i18n.locale = "zh-cn";
          }
        }
      }
    }
  },
  data() {
    return {
      langs: [
        { code: "en", text: "English" },
        { code: "de", text: "German" },
        { code: "fr", text: "French" },
        { code: "it", text: "Italian" },
        { code: "es", text: "Spanish" },
        { code: "jp", text: "Japanese" },
        { code: "zh-cn", text: "Chinese" },
      ],
    };
  },
};
</script>