<template>
    <div class="p-2 component chartBg barChart" v-if="formWithSelectedId != null && formWithSelectedId.length > 0" :class="'team-chart' + is_team_reports" :id="chart_id">
        <div class="row" v-if="!isEnlarged">
            <div class="col-12">
                <h2>{{ chartTitle }}<!-- {{ showTeamChart }} {{ is_all_reviews == null ? '' : is_all_reviews }} {{  selectedModel }} {{  selectedForm }}  {{ is_team_reports }} {{ chartIndex }} {{ is_dashboard }}--></h2>
            </div>
        </div>
        
        <div class="row">
            <div class="col-12" v-if="!is_team_reports && showMainChart">
               <vue-highcharts
                    type="chart"
                    :id="chart_id"
                    ref="myChart"
                    :options="getChartOptions"
                    :redrawOnUpdate="true"
                    :oneToOneUpdate="true"
                    :animateOnUpdate="true"
                    @updated="onUpdated"/>
            </div>
            <div class="col-12" v-else-if="showTeamChart">
               <vue-highcharts
                    type="chart"
                    ref="teamChart"
                    :options="getChartOptionsTeam"
                    :redrawOnUpdate="true"
                    :oneToOneUpdate="true"
                    :animateOnUpdate="true"
                    @updated="onUpdated"/>
            </div>
        </div>

        <div class="row" style="max-height: 30px; align-items: center; justify-content: space-between;" v-if="!isEnlarged">
            <div class="col-4">
                <button class="detailBtn" @click="this.$emit('showDetail', chartIndex)">{{ $t("View detail")}}</button>
            </div>
            <div class="col-4">
                <div class="reportIcons">
                    <div class="reportIcon iconPrint" @click="this.export()" title="Print"></div>
                    <div class="reportIcon iconDownload" title="Download" @click="generatePDF()"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
@import '@/assets/css/variables.scss';
</style>


<style>
@import 'https://code.highcharts.com/css/highcharts.css';


.highcharts-plot-background {
	fill: transparent;
}
.highcharts-plot-border {
	stroke-width: 2px;
	stroke: #7cb5ec;
}

.highcharts-series-0 .highcharts-point
{
    fill: #1D8B73;
    fill-opacity: 0.6;
    border-radius: 0;
}
.highcharts-series-1 .highcharts-point
{
    fill: #B94AFF;
}
.highcharts-series-2 .highcharts-point
{
    fill: #10DF99;
}
.highcharts-series-3 .highcharts-point
{
    fill: #FF935A;
}

.highcharts-background
{
    fill: transparent;
}

.highcharts-tooltip, .highcharts-tooltip-box {
    background: rgb(255 255 255 / 85%);
    border: none;
    fill: rgb(255 255 255 / 85%);
    stroke: rgb(255 255 255 / 85%);
}

.highcharts-tooltip > span
{
    color: black;
}

.html2pdf__container .chartBg
{
    background-color: white;
}

.html2pdf__container button, .html2pdf__container .reportIcons
{
    display: none !important;
}

.html2pdf__container h2
{
    color: black;
}

.html2pdf__container .windChart .highcharts-grid-line
{
    stroke: black !important;
    stroke-width: 3px !important;
}

.html2pdf__container .barChart .highcharts-yaxis-grid path
{
    stroke: black !important;
    stroke-width: 1px !important;
}

.html2pdf__container .highcharts-axis-labels text, .html2pdf__container .highcharts-yaxis-labels text, .html2pdf__container .highcharts-radial-axis-labels
{
    fill: black !important;
}
</style>

<script>
//import { computed } from 'vue';
import VueHighcharts from 'vue3-highcharts';
import Highcharts from 'highcharts'
//import exporting from "highcharts/modules/exporting.js";
import html2pdf from "html2pdf.js";
//exporting(Highcharts);

export default {
    name: 'BarChart',
    components:
    {
        VueHighcharts
    },
    setup()
    {
       
    },
    mounted()
    {
        this.showMainChart = true;
        this.showTeamChart = true;
    },
    methods:
    {
        export()
        {
            this.$refs.myChart.chart.exportChart();
        },
        generatePDF()
        {
            console.log(this.chart_id);
            var element = document.getElementById(this.chart_id);
            var opt = {
                margin:       0.1,
                filename:     'Chart Export',
                image:        { type: 'png', quality: 1 },
                jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }
            };
            html2pdf().set(opt).from(element).toContainer().save();
        },
        onUpdated()
        {
            
        },
        getCurrentScoreForElement(element_id)
        {
            if (this.is_team_reports)
            {
                console.log("Team reports");
            }
            else if (this.is_reports)
            {
                if (this.$store.state.reports_form_data != null)
                {
                    for (var z = 0; z < this.$store.state.reports_form_data.scores.length; z++)
                    {
                        //console.log("Z: " + JSON.stringify(this.$store.state.reports_form_data.scores));
                        if (this.$store.state.reports_form_data.scores[z].id == element_id)
                        {
                            return this.$store.state.reports_form_data.scores[z].score;
                        }
                    }
                    
                }
                return 0;
            }
            else if (this.is_all_reviews)
            {
                if (this.$store.state.allReviewsSelectedUserForms != null)
                {
                    var myForm = this.$store.state.allReviewsSelectedUserForms.forms.find(item => item.id == this.selectedForm);

                    for (var x = 0; x < myForm.scores.length; x++)
                    {
                        if (myForm.scores[x].id == element_id)
                        {
                            return myForm.scores[x].score;
                        }
                    }
                    return 0;
                }
                return 0;
            }
            else if (this.isCustomUser)
            {
                if (this.$store.state.dashboardSelectedReportData != null)
                {
                    console.log("Get score from custom dash data");
                    for (var e = 0; e < this.$store.state.dashboardSelectedReportData.scores.length; e++)
                    {
                        if (this.$store.state.dashboardSelectedReportData.scores[e].id == element_id)
                        {
                            //console.log("Found score (" + this.$store.state.newUserModel.user_forms[a].scores[b].score + ") for: " + element_id);
                            return this.$store.state.dashboardSelectedReportData.scores[e].score;
                        }
                    }
                }
            }
            else
            {
                
                for (var a = 0; a < this.$store.state.newUserModel.user_forms.length; a++)
                {
                    //console.log("Getting chart data for: " + this.selectedForm);
                    if (this.$store.state.newUserModel.user_forms[a].id == this.selectedForm)
                    {
                        //console.log("Found selected form!");
                        for (var b = 0; b < this.$store.state.newUserModel.user_forms[a].scores.length; b++)
                        {
                            if (this.$store.state.newUserModel.user_forms[a].scores[b].id == element_id)
                            {
                                //console.log("Found score (" + this.$store.state.newUserModel.user_forms[a].scores[b].score + ") for: " + element_id);
                                return this.$store.state.newUserModel.user_forms[a].scores[b].score;
                            }
                        }
                    }
                }
            }
            //console.log("Get score for: " + element_id + " / "  + this.$store.state.selectedForm);
            

            return 0;
        }
    },
    watch: {
        getChartOptionsTeam: {
            handler(newOptions) {
                console.log("Chart data changed, detected by watcher: " + JSON.stringify(newOptions.series));
                this.showTeamChart = false;
                setTimeout(()=>{
                    this.showTeamChart = true;
                },100);
                //console.log(JSON.stringify(this.$refs.teamChart.chart.series));
            },
        deep: true
        },
        reports_filter_company_avg()
        {
            this.$refs.myChart.chart.series[1].setVisible(this.reports_filter_company_avg);
        },
        reports_filter_company_role_avg()
        {
            this.$refs.myChart.chart.series[2].setVisible(this.reports_filter_company_avg);
        }
    },
    data () {
        return {
            numNum: Number,
            updateArgs: [true, true, {duration: 1000}],
            chartOptions: {},
            showTeamChart: false,
            showMainChart: false
        }
    },
    props:
    {   
        isEnlarged: Boolean,
        chartData: Array,
        chartTitle: String,
        catId: Number,
        chartIndex: Number,
        selectedModel: Number,
        selectedForm: Number,
        isCustomUser: Boolean,
        is_all_reviews: Boolean,
        is_reports: Boolean,
        chart_id: String,
        is_team_reports: Boolean,
        is_dashboard: Boolean
    },
    computed:
    {
        reports_filter_company_avg()
        {
            return this.$store.state.reports_filter_company_avg;
        },
        reports_filter_company_role_avg()
        {
            return this.$store.state.reports_filter_company_role_avg;
        },
        formWithSelectedId()
        {
            if (this.$store.state.newUserModel.model_definitions.find(x => x.model_id === this.selectedModel))
            {
                return this.$store.state.newUserModel.model_definitions.find(x => x.model_id === this.selectedModel).categories;    
            }
            return null;
        },
        getChartOptions()
        {
            //console.log("Get chart options!");
            var categories = [];
            var seriesData = [];
            var company_avg_role_series = [];
            var company_avg_series = [];

            if (this.$store.state.reports_all_form_data != null && this.$store.state.reports_all_form_data.average_scores_for_role[this.chartIndex-2] && !this.is_dashboard)
            {
                company_avg_role_series = this.$store.state.reports_all_form_data.average_scores_for_role[this.chartIndex-2].elements;
                console.log("Company role avg data: " + company_avg_role_series);
            }

            if (this.$store.state.reports_all_form_data != null && this.$store.state.reports_all_form_data.average_scores_for_role[this.chartIndex-2] && !this.is_dashboard)
            {
                company_avg_series = this.$store.state.reports_all_form_data.average_scores_for_company[this.chartIndex-2].elements;
                console.log("Company avg data: " + company_avg_series);
            }

            if (this.isCustomUser)
            {
                if (this.$store.state.dashboardSelectedReportData != null)
                {
                    for (var c = 0; c < this.formWithSelectedId.length; c++)
                    {
                        if (this.formWithSelectedId[c].id == this.catId)
                        {
                            //console.log("ID: " + this.formWithSelectedId[a].id);
                            for (var d = 0; d < this.formWithSelectedId[c].elements.length; d++)
                            {
                                //console.log("Element title: " + this.formWithSelectedId[a].elements[b].title);
                                categories.push(this.formWithSelectedId[c].elements[d].title);
                                seriesData.push(this.getCurrentScoreForElement(this.formWithSelectedId[c].elements[d].id));//this.chartData[a].score);
                            }
                        }
                    }
                }
            }
            else
            {
                if (this.selectedForm != null)
                {
                    //console.log("Not null! " + this.formWithSelectedId.length);
                    for (var a = 0; a < this.formWithSelectedId.length; a++)
                    {
                        if (this.formWithSelectedId[a].id == this.catId)
                        {
                            //console.log("ID: " + this.formWithSelectedId[a].id);
                            for (var b = 0; b < this.formWithSelectedId[a].elements.length; b++)
                            {
                                //console.log("Element title: " + this.formWithSelectedId[a].elements[b].title);
                                categories.push(this.formWithSelectedId[a].elements[b].title);
                                seriesData.push(this.getCurrentScoreForElement(this.formWithSelectedId[a].elements[b].id));//this.chartData[a].score);
                            }
                        }
                    }
                }
            }
            
            var chartOps = {
                chart: {
                    type: 'column',
                },
                exporting: {
                    enabled: false,
                    buttons: {
                        contextButton: {
                        enabled: false
                        }
                    }
                },
                credits: {
                    enabled: false
                },
                legend:
                {
                    enabled: false
                },
                title: {
                    text: '',
                },
                xAxis: {
                    categories: categories,
                    labels: { enabled: false }
                },
                yAxis: {
                    title: {
                        text: '',
                    },
                    max: 4.0
                },
                plotOptions:
                {
                    column:
                    {
                        borderRadius: 0
                    }
                },
                tooltip: {
                    useHTML: true,
                    formatter: function() {
                        return 'The score for <b>' + this.x + '</b> is <b>' + Highcharts.numberFormat(this.y, 2) + '</b>';
                    }
                },
                series: [{
                        name: 'Category',
                        data: seriesData,
                    }
                ],
            };

            //if (this.$store.state.reports_filter_company_avg)
            //{
                chartOps.series.push({ data: company_avg_series, type: "spline", name: "Average for company", visible: this.$store.state.reports_filter_company_avg, color: "#FF935A" });
                chartOps.series.push({ data: company_avg_role_series, type: "spline", name: "Average for role", visible: this.$store.state.reports_filter_company_role_avg, color: "#B94AFF" });
            //}

            //if (this.$store.state.reports_filter_company_role_avg)
            //{
                
            //}

            //console.log("Updating chart with: " + JSON.stringify(chartOps));
            return chartOps;
        },
        getChartOptionsTeam()
        {
            var categories = [];
            var seriesData = [];
            
            if (this.$store.state.team_report_data != null)
            {
                // Build categories
                for (var g = 0; g < this.$store.state.team_report_data.categories[0].elements.length; g++)
                {
                    categories.push(this.$store.state.team_report_data.categories[0].elements[g].title);
                }
                
                for (var n = 0; n < this.$store.state.team_report_data.selectedTeam.length; n++)
                {
                    var user_graph_data = { name: "" + this.$store.state.team_report_data.selectedTeam[n], data : []};

                    /*if (n == 0)
                    {
                        user_graph_data.color = "#ff0000";
                    }
                    if (n == 1)
                    {
                        user_graph_data.color = "#FF935A";
                    }
                    if (n == 2)
                    {
                        user_graph_data.color = "#B94AFF";
                    }*/
                    var user_data = this.$store.state.team_report_data.series_data[n].data[this.chartIndex-1];

                    /*for (var f = 0; f < this.$store.state.team_report_data.series_data[0].data.length; f++)
                    {
                        user_data.push(this.$store.state.team_report_data.series_data[0].data[f] + n);
                    }*/
                    user_graph_data.data = user_data;
                    seriesData.push(user_graph_data);
                }
            }
            
            //console.log(JSON.stringify(categories));
            //console.log(JSON.stringify(seriesData));
            
            var chartOps = {
                chart: {
                    type: 'column',
                },
                
                uniq:
                {
                    value: new Date()
                },
                exporting: {
                    enabled: false,
                    buttons: {
                        contextButton: {
                        enabled: false
                        }
                    }
                },
                credits: {
                    enabled: false
                },
                legend:
                {
                    enabled: false
                },
                title: {
                    text: '',
                },
                xAxis: {
                    categories: categories,
                    labels: { enabled: false }
                },
                yAxis: {
                    title: {
                        text: '',
                    },
                    max: 4.0
                },
                plotOptions:
                {
                    column:
                    {
                        borderRadius: 0
                    }
                },
                tooltip: {
                    useHTML: true,
                    formatter: function() {
                        return 'The score for <b>' + this.x + '</b> is <b>' + Highcharts.numberFormat(this.y, 2) + '</b>';
                    }
                },
                series: seriesData,
            };

            console.log("*** New chart data: " + JSON.stringify(chartOps));
            return chartOps;

        }
    }
}
</script>