const helpers = {
    data() {
      return { /* data */ }
    },
    methods: {
        getNextFourQuarters(sDate)
        {
            var next_quarters = [];

            for (var a = 1; a <= 4; a++)
            {
                var newDate = new Date(sDate.setMonth(sDate.getMonth()+(3)));
                next_quarters.push({ "q": this.getQuarter(newDate), "v": newDate } );
            }
            
            return next_quarters;
        },
        getDaysTillNextQuarter()
        {
            var today = new Date();
            var quarter = Math.floor((today.getMonth() + 3) / 3);
            var nextq;
            if (quarter == 4) {
                nextq = new Date (today.getFullYear() + 1, 1, 1);
            } else {
                nextq = new Date (today.getFullYear(), quarter * 3, 1);
            }
            var millis1 = today.getTime();
            var millis2 = nextq.getTime();
            var daydiff = (millis2 - millis1) / 1000 / 60 / 60 / 24;
            return daydiff;
        },
        getAvailableQuarters(sDate, eDate)
        {
            if (sDate == null)
            {
                sDate = new Date();
            }
            // Ensure start is the earlier date;
            if (sDate > eDate) 
            {
                var t = eDate;
                eDate = sDate;
                sDate = t;
            }
    
            // Copy input start date do don't affect original
            sDate = new Date(sDate);
            
            // Set to 2nd of month so adding months doesn't roll over
            // and not affected by daylight saving
            sDate.setDate(2);
    
            // Initialise result array with start quarter
            var startQ = this.getQuarter(sDate);
            var endQ   = this.getQuarter(eDate);
            this.availableQuarters = [startQ];
            
            // List quarters from start to end
            while (startQ != endQ) 
            {
                sDate.setMonth(sDate.getMonth() + 3);
                startQ = this.getQuarter(sDate);
                this.availableQuarters.push(startQ);
            } 
            
            return this.availableQuarters;
        },
        getQuarter(date) 
        {
            //console.log("Creating quarter string from date: " + date);
            if (date == null || date == "")
            {
                date = new Date();
                return 'Q' + Math.ceil((date.getMonth()+ 1)/3) + " " + date.getFullYear();
            }
            //date = date * 1000;
            
            date = new Date(date);

            if (date.getFullYear() == 1970)
            {
                date = new Date(date * 1000)
            }

            return 'Q' + Math.ceil((date.getMonth()+ 1)/3) + " " + date.getFullYear();
        },
        getQuarterNoYear(date) 
        {
            //console.log("Creating quarter string from date: " + date);
            if (date == null || date == "")
            {
                date = new Date();
                return 'Q' + Math.ceil((date.getMonth()+ 1)/3);
            }
            date = new Date(date);
            return 'Q' + Math.ceil((date.getMonth()+ 1)/3);
        }
    },
    // etc.
  }
  
  export default helpers;
