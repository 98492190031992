<template>
<!-- Registration Form -->
<div class="col-8 mt-5">
      <div class="row">
        <div class="col-12">
          <h1>{{ $t("Welcome to Kiverno")}}</h1>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <p>{{ $t("Register for an account below")}}</p>
        </div>
      </div>

      <div class="row" v-show="validatedEmail" :class="{'hidden': isHidden}">
        <div class="col-6">
          <label>{{ $t("First name")}}</label>
        </div>
        <div class="col-6">
          <label>{{ $t("Last name")}}</label>
        </div>
      </div>

      <div class="row" v-show="validatedEmail" :class="{'hidden': isHidden}">
        <div class="col-6">
          <input type="text" id="firstname" name="firstname" :placeholder=" $t('First name') " v-model="firstname" required autocomplete="off" data-value-missing="This field is required!" />
          <span class="error" v-if="v$.firstname.$error"> {{ v$.firstname.$errors[0].$message }} </span>
        </div>
        <div class="col-6">
          <input type="text" id="lastname" name="lastname" :placeholder="$t('Last name')" v-model="lastname" required autocomplete="off"/>
          <span class="error" v-if="v$.lastname.$error"> {{ v$.lastname.$errors[0].$message }} </span>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <label>{{ $t("Email address")}}</label>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <input type="email" id="email" name="email" :placeholder="$t('email_placeholder')" v-model="emailAddress" required autocomplete="off" :readonly="validatedEmail" />
          <!--<span class="error" v-if="v$.email.$error"> {{ v$.email.$errors[0].$message }} </span>-->
        </div>
      </div>

      <div class="row mt-3" v-show="validatedEmail">
        <div class="col-6" :class="{'hidden': isHidden}">
          <label v-tooltip="{ content: $t('Tooltip Title'), html: true }">{{ $t("Role")}} <i class="bi bi-question-circle-fill"></i></label>
        </div>
        <div class="col-6" :class="{'hidden': isHidden && this.$store.state.registration_data.client_id != 11164}">
          <label v-tooltip="{ content: $t('Tooltip Role'), html: true }">{{ $t("Time in role")}}  <i class="bi bi-question-circle-fill"></i></label>
        </div>
      </div>

      <div class="row" v-show="validatedEmail">
        <div class="col-6" :class="{'hidden': isHidden}">
          <!--<input type="text" id="role" name="role" placeholder="Role" />-->
          <CustomDropDown v-if="this.$store.state.registration_data != null" :dropDownItems="this.$store.state.registration_data.available_roles" @selectItem="selectRole"></CustomDropDown>
          <input type="hidden" v-model="roleId" />
        </div>
        <div class="col-6" :class="{'hidden': isHidden && this.$store.state.registration_data.client_id != 11164}">
          <CustomDropDown v-if="this.$store.state.registration_data != null" :dropDownItems="this.$store.state.registration_data.time_in_role" @selectItem="selectTimeInRole"></CustomDropDown>
          <input type="hidden" v-model="timeInMarketId" />
        </div>
      </div>

      <div class="row mt-3" v-show="validatedEmail" :class="{'hidden': isHidden && this.$store.state.registration_data.client_id != 11164}">
        <div class="col-6">
          <label v-tooltip="{ content: $t('Tooltip Brand'), html: true }">{{ $t("Therapy Area")}}  <i class="bi bi-question-circle-fill"></i></label>
        </div>
        <div class="col-6" :class="{'hidden': isHidden}" v-if="this.$store.state.registration_data != null && this.$store.state.registration_data.managers != null && this.$store.state.registration_data.managers.length > 0">
          <label v-tooltip="{ content: $t('Tooltip Manager'), html: true }">{{ $t("Reporting manager")}}  <i class="bi bi-question-circle-fill"></i></label>
        </div>
      </div>

      <div class="row" v-show="validatedEmail">
        <div class="col-6" :class="{'hidden': isHidden && this.$store.state.registration_data.client_id != 11164}">
          <CustomDropDown v-if="this.$store.state.registration_data != null" :dropDownItems="this.$store.state.registration_data.brands" @selectItem="selectBrand"></CustomDropDown>
          <input type="hidden" v-model="brandId" />
        </div>
        <div class="col-6" :class="{'hidden': isHidden }">
          <CustomDropDown v-if="this.$store.state.registration_data != null && this.$store.state.registration_data.managers != null && this.$store.state.registration_data.managers.length > 0" :dropDownItems="this.$store.state.registration_data.managers" @selectItem="selectManager"></CustomDropDown>
          <input type="hidden" v-model="userID" />
        </div>
      </div>

      <div class="row mt-3" v-show="validatedEmail">
        <div class="col-6">
          <label v-tooltip="{ content: $t('Tooltip Region'), html: true }">{{ $t("Region")}}  <i class="bi bi-question-circle-fill"></i></label>
        </div>
        <div class="col-6">
          <label>{{ $t("Language")}}</label>
        </div>
      </div>
      <div class="row" v-show="validatedEmail">
        <div class="col-6">
          <CustomDropDown v-if="this.$store.state.registration_data != null" :dropDownItems="this.$store.state.registration_data.regions" @selectItem="selectRegion"></CustomDropDown>
          <input type="hidden" v-model="regionId" />
        </div>
        <div class="col-6">
          <CustomDropDown :initialValue="getSelectedLanguage" v-if="this.$store.state.registration_data != null" :dropDownItems="this.$store.state.registration_data.languages" @selectItem="selectLanguage"></CustomDropDown>
          <input type="hidden" v-model="languageId" />
        </div>
      </div>


      <div class="row mt-3" v-show="validatedEmail">
        <div class="col-6">
          <label>{{ $t("Create password")}}</label>
        </div>
        <div class="col-6">
          <label>{{ $t("Confirm password")}}</label>
        </div>
      </div>

      <div class="row" v-show="validatedEmail">
        <div class="col-6">
          <input type="password" id="password" name="password" v-model="password.password" :placeholder="$t('Create password')" autocomplete="new-password" />
          <span class="error" v-if="v$.password.password.$error"> {{ v$.password.password.$errors[0].$message }} </span>
        </div>
        <div class="col-6">
          <input type="password" id="password2" name="password2" v-model="password.confirm" :placeholder="$t('Confirm password')" autocomplete="new-password"/>
          <span class="error" v-if="v$.password.confirm.$error"> {{ v$.password.confirm.$errors[0].$message }} </span>
        </div>
      </div>

      <div class="row mt-3" v-show="validatedEmail">
        <div class="col-12">
          <button @click="submitForm">{{ $t("Register")}}</button>
        </div>
      </div>
      <p v-for="error of v$.$errors" :key="error.$uid"><strong>{{ error.$validator }}</strong>
        <small> on property</small>
        <strong>{{ error.$property }}</strong>
        <small> says:</small>
        <strong>{{ error.$message }}</strong>
      </p>
    

      

      <div class="row mt-3" v-show="!validatedEmail">
        <div class="col-12">
          <button @click="validateEmailAddress">{{ $t("Validate")}}</button>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <p><small>{{ $t("Already have an account?")}} &nbsp;&nbsp;<a @click="this.$emit('toggleLogin')">{{ $t("Login")}}</a></small></p>
        </div>
      </div>
    
  </div>
</template>

<style>
.error
{
  color: red;
  font-size: 12px;
}
.hidden
{
  display: none !important;
}

.v-popper--theme-tooltip .v-popper__inner
{
    background-color: rgba(16, 24, 43, 0.9);
}
.v-popper__popper {
  z-index: 10000;
  top: 0;
  left: 0;
  outline: none;
  max-width: 800px;
}

</style>

<script>
import 'floating-vue/dist/style.css'

import axios from 'axios'
import CustomDropDown from './CustomDropDown.vue';
import useValidate from '@vuelidate/core'
import { required, minLength, sameAs, email } from '@vuelidate/validators'

export default {
    name: 'RegistrationForm',
    components:
    {
      CustomDropDown,
    },
    computed:
    {
      isHidden()
      {
        if (this.$store.state.registration_data != null)
        {
          return this.$store.state.registration_data.account_configured;
        }
        return false;
      },
      getSelectedLanguage()
      {
        if (this.$store.state.registration_data.languages != null)
        {
          for (var x = 0; x < this.$store.state.registration_data.languages.length; x++)
          {
            if (this.$store.state.registration_data.languages[x].title.toLowerCase() == this.$store.state.registration_data.lang)
            {
              return x;
            }
          }
        }
        return 0;
        
      }
    },
    methods:
    {
      selectRole(id)
      {
        console.log("Select role: " + id);
        this.roleId = id;
      },
      selectTimeInRole(id)
      {
        console.log("Select time in role: " + id);
        this.timeInMarketId = id;
      },
      selectBrand(id)
      {
        console.log("Select brand: " + id);
        this.brandId = id;
      },
      selectRegion(id)
      {
        console.log("Select region: " + id);
        this.regionId = id;
      },
      selectLanguage(id)
      {
        console.log("Select language: " + id);
        this.languageId = id;
      },
      selectManager(id)
      {
        this.userID = id;
        console.log("Select manager: " + id);
      },
      submitForm() {
        this.v$.$validate();
        if (!this.v$.$error) 
        {
          // if ANY fail validation
          this.registerUser();
          
        } 
        else 
        {
          console.log('Form failed validation ' + JSON.stringify(this.v$));
        }
      },
      setNames(firstName, lastName)
      {
        this.firstname = firstName;
        this.lastname = lastName;
      },
      setEmail(email)
      {
        this.emailAddress = email;
      },
      registerUser()
      {
        var uniqId = this.$route.query.uniqId;

        console.log("Finishing registration: " + uniqId);
        console.log("First name: " + this.firstname);
        console.log("Last name: " + this.lastname);
        console.log("Email: " + this.emailAddress);
        console.log("Password: " + this.password.password);
        console.log("Language: " + this.languageId);
        console.log("Brand: " + this.brandId);
        console.log("Region: " + this.regionId);
        console.log("Manager: " + this.userID);
        console.log("Time in Role: " + this.timeInMarketId);
        console.log("Role: " + this.roleId);

        axios.post(this.$store.state.kivernoAPIEndPoint + 'finishRegistration/1', 
            {
              "uniqId": uniqId,
              "email": this.emailAddress, 
              "language": this.languageId,
              "firstname": this.firstname,
              "lastname": this.lastname,
              "emailAddress": this.emailAddress,
              "password": this.password.password,
              "brandId": this.brandId,
              "regionId": this.regionId,
              "userID": this.userID,
              "timeInMarketId": this.timeInMarketId,
              "roleId": this.roleId
            }
            
        ).then((response) => 
        {
          console.log(response.data);
          if (response.data.ok)
          {
            // Auto login now they are registered
            this.$emit('login', this.emailAddress, this.password.password);
          }
        }).catch((error) => 
        {
          if (error.response)
          {
            alert(error.response.data.error);
          }
          
        });

      },
      setEmailValidated()
      {
        this.validatedEmail = true;
      },
      validateEmailAddress()
      {
        var langId = 0;
        switch (this.$i18n.locale)
        {
          case "en":
            langId = 1102;
          break;
          case "es":
            langId = 1106;
          break;
          case "fr":
            langId = 1104;
          break;
          case "it":
            langId = 1105;
          break;
          case "de":
            langId = 1107;
          break;
          case "jp":
            langId = 1108;
          break;
          case "zh-cn":
            langId = 11163;
          break;
        }
        console.log("Validating email address");
        axios.post(this.$store.state.kivernoAPIEndPoint + 'validateEmail/1', {email: this.emailAddress, "language": langId}).then((response) => 
        {
          console.log(response);
          if (response.data.registered)
          {
            alert(this.$t("New account created."));
          }
        }).catch((error) => 
        {
          if (error.response)
          {
            alert(error.response.data.error);
          }
          
        });
      },
    },
    data () {
        return {
          v$: useValidate(),
          validatedEmail: false,
          firstname: "",
          lastname: "",
          brandId: 0,
          userID: 0,
          clientID: 0,
          timeInMarketId: 0,
          languageId: 0,
          roleId: 0,
          regionId: 0,
          emailAddress: "",
          password:
          {
            password: "",
            confirm: ""
          },
          errorMsg: "This domain does not exists with any client please enter valid domain.",
        }
    },
    validations()
    {
      return {
        emailAddress: { required, email },
        firstname: { required },
        lastname: { required },
        password:
        {
          password: { required, minLength: minLength(6) },
          confirm: { required, sameAsPassword: sameAs(this.password.password) }
        }
      }
    },
    props:
    {
      
    }
}
</script>