<template>
  
    <!-- My Review -->
  <div>
    <div class="p-2 component" v-if="formWithSelectedId != null && getCurrentForm != null">
      <div class="row mt-2 mb-3">
        <div class="col-6">
          <h2>{{ $t("My review")}}</h2>
          <h3>{{ getQuarter(new Date(getCurrentForm.date_of_review * 1000)) }}</h3><!--Locked: {{ getCurrentForm.is_committed }}-->
        </div>
        <div class="col-6">
          <div class="row smallButtonRow">
            <div class="col-3 smallButton" v-if="!getCurrentForm.is_committed">
              <button class="" @click="loadReview(getCurrentForm.id, this.$store.state.latestReviewModelId)">{{ $t("Update")}}</button>
            </div>
            <div class="col-3 smallButton inverted">
              <button class="" @click="this.$emit('navClick', 1)">{{ $t("New")}}</button>
            </div>
          </div>
        </div>
      </div>

      
      <div class="accordion" id="accordionExample">
        <!-- Loop Category Descriptions -->
        <div class="accordion-item" v-for="(Review, index) in formWithSelectedId.categories" :key="index">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse' + index" aria-expanded="false" :aria-controls="'collapse' + index">
              {{ Review.title }}
            </button>
          </h2>
          <!-- Loop Sub Items-->
          <div :id="'collapse' + index" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <div class="row myReviewRow" v-for="(Element, index2) in Review.elements" :key="index2">
                <div class="col-10">
                  <p>{{ Element.title }}</p>
                </div>
                <div class="col-2">
                  <div class="numberCircle filled">{{ getScoreForElement(Element.id) }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="p-2 component">
      <p>Not user data yet!</p>
    </div>
  </div>
</template>

<script>
import helpers from '@/helpers/helpers';
import axios from 'axios'

export default {
    
    name: 'MyReview',
    mixins: [helpers],
    components:
    {
        
    },
    methods:
    {
      loadReview(formId, modelId)
      {
        //console.log("Loading form: " + formId + " with model: " + modelId);

        this.$store.commit('setSelectedForm', formId);
        this.$store.commit('setSelectedModel', modelId);

        this.$emit('navClick', 1);
      },
      getScoreForElement(elementId)
      {
        if (this.$store.state.dashboardSelectedUser != null)
        {
          //console.log("Looking for: " + elementId);
          if (this.$store.state.dashboardSelectedForm.scores.find(x => x.id === elementId) != null)
          {
            return this.$store.state.dashboardSelectedForm.scores.find(x => x.id === elementId).score;
          }
        }
        else
        {
          if (this.$store.state.newUserModel.user_forms.find(x => x.id === this.$store.state.selectedForm).scores.find(x => x.id === elementId) != null)
          {
            return this.$store.state.newUserModel.user_forms.find(x => x.id === this.$store.state.selectedForm).scores.find(x => x.id === elementId).score;
          }
        }

        return '';
      },
      getNewFormData(formId)
      {
        //console.log("Store state: " + this.$store.state.dashboardSelectedForm);
        if (this.$store.state.dashboardSelectedForm == {} || this.$store.state.dashboardSelectedForm == null)
        {
          axios.get(this.$store.state.kivernoAPIEndPoint + 'getForm/' + formId).then((response) => 
          {
            //console.log("getNewForm response: " + response.data);
            this.$store.commit('setDashboardSelectedForm', response.data);
          });
        }
        
      }
    },
    data () {
        return {
        
        }
    },
    props:
    {
      repoHistory: Array,
      categoryDescriptions: Array
    },
    computed:
    {
      formWithSelectedId()
      {
        if (this.$store.state.dashboardSelectedUser != null)
        {
          var available_reviews = this.$store.state.newUserModel.sub_users.find(x => x.id == this.$store.state.dashboardSelectedUser).available_reviews;
          //var userModelId = available_reviews[available_reviews.length - 1].model_reference;
          var currentReview = available_reviews.filter(item => item.is_self == true);
          if (currentReview.length <= 0)
          {
            return null;
          }
          var userModelId = currentReview[0].model_reference;
          //console.log("Finding model for other user: " + this.$store.state.dashboardSelectedUser + " and model: " + userModelId);
          //console.log("Form data to load: " + available_reviews[available_reviews.length - 1].id);
          
          this.getNewFormData(available_reviews[available_reviews.length - 1].id);
          
          return this.$store.state.newUserModel.model_definitions.find(x => x.model_id === userModelId);
        }
        else 
        {
          //console.log("Finding model for current user: " + this.$store.state.latestReviewModelId);
          return this.$store.state.newUserModel.model_definitions.find(x => x.model_id === this.$store.state.latestReviewModelId);
        }
          
      },
      getCurrentForm()
      {
        if (this.$store.state.dashboardSelectedUser != null)
        {
          return this.$store.state.dashboardSelectedForm;
        }
        else
        {
          return this.$store.state.newUserModel.user_forms.find(x => x.id === this.$store.state.latestReviewFormId);
        }
        
      }
    }
}
</script>