<template>
    <div class="dropdown kivernoDropdown" v-if="available_charts != null && available_charts != [] && available_charts.length > 0">
        <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        {{ available_charts[selectedIndex].title }}
        <!-- this.$store.state.newUserModel.available_quarters[selectedIndex] -->
        </button>
        <ul class="dropdown-menu">
            <li v-for="(dropdownItem, index) in available_charts" :key="index"><a @click="selectItem(index)" class="dropdown-item" href="#">{{ dropdownItem.title }}</a></li>
        </ul>
    </div>
</template>


<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

.kivernoDropdown
{
    border: 1px solid $bright-green;
    border-radius: 4px;
}

</style>

<script>
//import helpers from '@/helpers/helpers'

export default {
    //mixins: [helpers],
    name: 'AvailableCharts',
    components:
    {
        
    },
    mounted()
    {
        if (this.available_charts != undefined && this.available_charts != [])
        {
            this.selectItem(0);
        }
    },
    methods:
    {
        selectItem(index)
        {
            if (this.available_charts != null && this.available_charts != [] && this.available_charts.length > 0)
            {
                this.selectedIndex = index;
                this.$emit('selectChart', this.selectedIndex);
            }
            
        }
    },
    data () {
        return {
            selectedIndex: 0
        }
    },
    props:
    {
        available_charts: Array
    }
}
</script>