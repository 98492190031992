<template>
  
    <div class="p-2 component">
      <div class="row mt-2">
        <div class="col-6">
          <h2 v-if="componentType==2">{{ $t("Incomplete reviews")}}</h2>
          <h2 v-if="componentType==1">{{ $t("Completed reviews")}}</h2>
          <h2 v-if="componentType==0">{{ $t("My peer reviews")}}</h2>
        </div>
        <div class="col-6">
          <div class="row smallButtonRow">
            <div class="col-5 smallButton">
              <button class="" @click="showAllReviews">{{ $t("All reviews")}}</button>
            </div>
          </div>
        </div>
      </div>

      <div v-if="this.$store.state.newUserModel.user_forms != null">
        
        <div class="row reviewRow" v-for="(review, index) in getForm" :key="index">
          <div class="col-2">
            <img src="../assets/img/photo.svg" class="img-fluid" />
          </div>
          <div class="col-8">
            <div class="row">
              <div class="col-12 dateCol">
                <div class="row">
                  <div class="col-10">
                    <p><b v-html="getDateStr(new Date(review.date_of_review * 1000))"></b></p>
                  </div>
                  <div class="col-2">
                    <div class="quarter">{{ getQuarterNoYear(review.date_of_review == null ? Date() : Date(review.date_of_review * 1000)) }}</div>
                  </div>
                </div>
                
                
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <p>{{ review.model_name }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <p>{{ review.BrandName }}</p>
              </div>
            </div>
          </div>
          <div class="col-2 reviewBtnCol justify-content-start">
            <div class="reviewBtn" @click="loadReview(review.id, review.model_reference)">
              <i class="bi bi-chevron-right"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import helpers from '@/helpers/helpers';

export default {
    name: 'CompletedReviews',
    mixins: [helpers],
    methods:
    {
        getDateStr(theDate)
        {
          const day = theDate.getDate();
          const month = theDate.toLocaleString("default", { month: "short" });
          const year = theDate.getFullYear();
          const nth = this.nthNumber(day);

          const date = `${day}${nth} ${month} ${year}`;
          return date;
        },
        nthNumber(number)
        {
          if (number > 3 && number < 21) return "<sup>th</sup>";
          switch (number % 10) {
            case 1:
              return "st";
            case 2:
              return "nd";
            case 3:
              return "rd";
            default:
              return "<sup>th</sup>";
          }
        },
        showAllReviews()
        {
          this.$emit('navClick', 3);
        },
        loadReview(formId, modelId)
        {
          //console.log("Loading form: " + formId + " with model: " + modelId);

          this.$store.commit('setSelectedForm', formId);
          this.$store.commit('setSelectedModel', modelId);

          this.$emit('navClick', 3);
        }
    },
    computed:
    {
      getForm()
      {
        // Peer reviews
        if (this.componentType == 0)
        {
          // Peer Reviews
          if (this.$store.state.dashboardSelectedUser != null)
          {
            var theUser = this.$store.state.newUserModel.sub_users.filter(eachUser => eachUser.id == this.$store.state.dashboardSelectedUser)[0]; 
            return theUser.available_reviews.filter(review => (review.is_self == this.componentType));
          }
          else
          {
            return this.$store.state.newUserModel.user_forms.filter(eachForm => (eachForm.is_self == false) && eachForm.is_committed);
          }
        }
        // Completed reviews
        else if (this.componentType == 1)
        {
          return this.$store.state.newUserModel.user_forms.filter(eachForm => (eachForm.is_self == this.componentType) && eachForm.is_committed);
        }
        // Incomplete reviews
        else if (this.componentType == 2)
        {
          return this.$store.state.newUserModel.user_forms.filter(eachForm => (eachForm.is_self == true)  && !eachForm.is_committed);
        }

        return null;
      }
    },
    data()
    {
        return {
            
        }
    },
    props:
    {
        componentType: Number,
        repoData: Array
    }
}
</script>