<template>
    <div class="myReportees" v-if="this.$store.state.newUserModel.reportees.length > 0">

        <div class="row">
            <div class="col-12">
                <hr />
            </div>
        </div>
        
        <div class="row">
            <div class="col-12">
                <p class="dd-title mt-3 mb-2">{{ $t("My reportees")}}</p>
            </div>
        </div>

        <div class="row menuUserRow" v-for="(reportee, index) in this.$store.state.newUserModel.reportees" :key="index" @click="this.$emit('selectUser', reportee.id)">
            <div class="col-2">
                <div class="iconWrapper">
                    <i class="bi bi-person"></i>    
                </div>
            </div>
            <div class="col-10 userRow">
                <p>{{ reportee.name }}<br />
                <span class="jobTitle">{{ Array.prototype.map.call(reportee.roles, function(item) { return item.title; }).join(", ") }}</span></p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'MyReportees',
    components:
    {
        
    },
    methods:
    {
    },
    data()
    {
        return {
            
        }
    },
    props:
    {
        formRepoFilterData: {}
    }
}
</script>


<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

.myReportees
{
    padding-left: 20px;
    padding-right: 20px;
}
.btn
{
    background-color: #0F3339;
    width: 30px !important;
    height: 26px;
    padding: 0;
    margin: 0;
    margin-left: 10px;
    margin-top: -8px;
}

.dd-title
{
    font-size: 10px !important;
    font-family: $fonts !important;
    font-weight: $regular !important;
    color: #B7C3DB !important;
    padding: 0;
    margin: 0;
}

.menuUserRow
{
    padding-top: 7px;
    padding-bottom: 7px;
    cursor: pointer;
    transition: background-color 0.45s ease;
}

.menuUserRow:hover
{
    transition: background-color 0.45s ease;
    background-color: #293A50;
    border-radius: 4px;
}

.menuUserRow p
{
    font-size: 12px;
    font-weight: $semiBold;
    color: white;
}
.jobTitle
{
    font-weight: $regular;
    color: #808A9D;
}

.btn i
{
    color: #12CD90;
}
.dropdown
{
    display: inline-block;
}

.dropdown-menu
{
    width: 284px;
    height: 271px;
    background-color: #32405C;
    border: none;
}

.iconWrapper
{
    background-color: #1C394B;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-radius: 50px;
    width: 32px;
    height: 32px;
}

.iconWrapper i
{
    font-size: 20px;
    color: #1B5858;
}

.userRow
{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.userRow p
{
    padding: 0;
    margin: 0;
}

</style>