<template>
    <div class="row">
        <!-- Left Column -->
        <div class="col-12 col-md-6 lightBlueBg flexCenteredCol">

          <!-- Logos -->  
          <div class="row justify-content-center align-items-center">
            <div class="col-10">
              <div class="row align-items-center mb-3">
                <div class="col-6">
                  <img src="../assets/img/KIVERNO_logo.svg" class="img-fluid" />
                </div>
                <div class="col-6" style=" height: 100%; display: flex; flex-direction: column; justify-content: center;">
                  <img src="../assets/img/be-possible-logo.svg" class="img-fluid" style="max-height: 30px;"/>
                </div>
              </div>
            </div>
          </div>
          <!-- Video -->
          <div class="row justify-content-center">
            <div class="col-10">
              <video width="100%" control autoplay muted loop id="theVideo" ref="theVideo">
                <source :src="this.landingVideo" type="video/mp4">
              </video>
            </div>
          </div>
        </div>

        <!-- Right Column -->
        <div class="col-12 col-md-6 darkBlueBg mainRightCol" :class="{'login': (this.showLogin || (!this.showLogin && this.$route.query.uniqId == null))}">
          

            
                  <div class="innerWrapper" style="width: 50%;">
                    <div class="row justify-content-center">
                      <transition name="slide-fade">
                        
                          <div class="col-8" v-show="this.loggingIn">
                            <div class="row">
                              <div class="col-12">
                                <p style="width: 100%; text-align: center; color: white;">Loading data...</p>
                              </div>
                            </div>
                          </div>
                        
                        <!--<RegistrationForm v-if="!this.showLogin" @toggleLogin="toggleLogin"></RegistrationForm>-->
                      </transition>
                    </div>
                  </div>

              
                  <transition name="slide-fade">
                    <LoginForm v-show="this.showLogin && !this.loggingIn && !this.showReset" @login="login" @toggleLogin="toggleLogin"></LoginForm>
                    <!--<RegistrationForm v-if="!this.showLogin" @toggleLogin="toggleLogin"></RegistrationForm>-->
                  </transition>
                

              
                
                  <transition name="slide-fade">
                    <RegistrationForm v-show="!this.showLogin" @toggleLogin="toggleLogin" @login="login" ref="regForm"></RegistrationForm>
                  </transition>
                
              

              
                  <transition name="slide-fade">
                    <PasswordResetForm v-show="this.showReset" ref="resetForm" @resetPassword="resetPassword"></PasswordResetForm>
                  </transition>
              
              <div class="row justify-content-center">
                <div class="col-8">
                  <LocaleChanger></LocaleChanger>
                </div>
              </div>


            
          
          
          

        </div>            
      </div>
</template>

<style>
/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #ffffffaa #5c5c5c;
  }

</style>
<script>
import LocaleChanger from "@/components/LocaleChanger.vue";
import LoginForm from './LoginForm.vue';
import RegistrationForm from './RegistrationForm.vue';
import PasswordResetForm from "./PasswordResetForm.vue";
import axios from 'axios';

export default {
    name: 'LoginScreen',
    components:
    {
      LoginForm,
      RegistrationForm,
      PasswordResetForm,
      LocaleChanger
    },
    mounted()
    {
      var uniqId = this.$route.query.uniqId;
      var reg_email = this.$route.query.email;
      var reset_token = this.$route.query.reset;

      if (uniqId != null && reg_email != null && uniqId.length > 0 && reg_email.length > 0)
      {
        if (this.$store.state.kivernoAPIEndPoint == null || this.$store.state.kivernoAPIEndPoint == "")
        {
          // wait for init
          setTimeout(() => {
            this.checkRegDetails();
          }, 500);
        }
        else
        {
          this.checkRegDetails();
        }
      }
      else if (reset_token != null && reg_email != null && reset_token.length > 0 && reg_email.length > 0)
      {
        if (this.$store.state.kivernoAPIEndPoint == null || this.$store.state.kivernoAPIEndPoint == "")
        {
          // wait for init
          setTimeout(() => {
            this.checkResetToken();
          }, 500);
        }
        else
        {
          this.checkResetToken();
        }
      }
    },
    data()
    {
      return {
        showLogin: true,
        activeComponent: 'RegistrationForm',
        showReset: false
      }
    },
    props:
    {
      loggingIn: Boolean,
      landingVideo: String
    },
    methods:
    {
      loadVideo()
      {
        this.$refs.theVideo.load();
      },
      resetPassword(password)
      {
        var uniqId = this.$route.query.reset;
        var reg_email = this.$route.query.email;
        console.log("Email: " + reg_email);
        console.log("Reset token: " + uniqId);
        console.log("Resetting password to: " + password);

        axios.post(this.$store.state.kivernoAPIEndPoint + 'resetUserPassword/1', {"email": reg_email, "uniqId": uniqId, "password": password }).then((response) => 
        {
          console.log(response);
          if (response.data.ok)
          {
            alert("Your password has been reset and you can now login");
            this.showLogin = true;
            this.showReset = false;
          }
          
        }).catch((error) => 
        {
          if (error.response)
          {
            alert(error.response.data.error);
          }
          
        });

      },
      checkRegDetails()
      {
        var uniqId = this.$route.query.uniqId;
        var reg_email = this.$route.query.email;

        axios.post(this.$store.state.kivernoAPIEndPoint + 'getRegistration/1', {"email": reg_email, "uniqId": uniqId }).then((response) => 
        {
          console.log(response);
          this.$store.commit('set_registration_data', response.data);
          this.toggleLogin();
          this.$refs.regForm.setNames(response.data.first_name, response.data.last_name);
          this.$refs.regForm.setEmail(response.data.email);
          this.$refs.regForm.setEmailValidated(true);

          if (response.data.lang == "spanish")
          {
            this.$i18n.locale = "es";
          }
          if (response.data.lang == "french")
          {
            this.$i18n.locale = "fr";
          }
          if (response.data.lang == "italian")
          {
            this.$i18n.locale = "it";
          }
          if (response.data.lang == "german")
          {
            this.$i18n.locale = "de";
          }
          if (response.data.lang == "japanese")
          {
            this.$i18n.locale = "jp";
          }
          if (response.data.lang == "mandarin-simple")
          {
            this.$i18n.locale = "zh-cn";
          }

          this.showLogin = false;
        }).catch((error) => 
        {
          if (error.response)
          {
            alert(error.response.data.error);
          }
          
        });
      },
      checkResetToken()
      {
        var uniqId = this.$route.query.reset;
        var reg_email = this.$route.query.email;

        axios.post(this.$store.state.kivernoAPIEndPoint + 'checkPasswordReset/1', {"email": reg_email, "uniqId": uniqId }).then((response) => 
        {
          if(response.data.ok)
          {
            console.log("OK");
            this.showReset = true;
          }
          else
          {
            alert("Invalid token or email address");
          }
          
        }).catch((error) => 
        {
          if (error.response)
          {
            alert(error.response.data.error);
          }
          
        });
      },
      login(username, password)
      {
        //this.loggingIn = true;
        this.$emit('login', username, password);
      },
      toggleLogin()
      {
        console.log("Login: " + this.showLogin);
        this.showLogin = !this.showLogin;

        if (this.showLogin)
        {
          this.activeComponent = "LoginForm";
        }
        else
        {
          this.activeComponent = "RegistrationForm";
        }
      }
    }
}
</script>