<template>
    <!-- Left Nav -->
    <div class="leftNav d-xl-flex d-none" :class="getNavClass()">
        <div class="topBlock">
        <div class="row mt-4 mb-4">
            <div class="col-10 kivernoLogo" id="mainLogo" @click="toggleNav();">
            <img src="../assets/img/Kiverno-K.svg" class="navIcon" />
            <span class="kiverno">KIVERNO</span>
            </div>
        </div>

        <div class="row navRow mb-4" @click="navClick(0)" :class="this.$route.path == '/dashboard' ? 'selected' : ''" id="dashLink">
            <div class="selection" v-if="this.$route.path == '/dashboard'"></div>
            <div class="col-10">
            <i class="bi bi-grid navIcon"></i>
            <span>{{ $t("Dashboard")}}</span>
            </div>
        </div>

        <div class="row navRow mb-4" @click="navClick(1)" :class="this.$route.path == '/new-review' ? 'selected' : ''">
            <div class="selection" v-if="this.$route.path == '/new-review'"></div>
            <div class="col-10">
            <i class="bi bi-plus-circle navIcon"></i>
            <span>{{ $t("New review")}}</span>
            </div>
        </div>

        <div class="row navRow mb-4" @click="navClick(2)"  :class="[this.$route.path == '/new-targets' ? 'selected' : '', this.$store.getters.isFirstReview ? 'greyedOut' : '']">
            <div class="selection" v-if="this.$route.path == '/new-targets'"></div>
            <div class="col-10">
            <i class="bi bi-crosshair navIcon"></i>
            <span>{{ $t("My targets")}}</span>
            </div>
        </div>

        <div class="row navRow mb-4" @click="navClick(3)" :class="this.$route.path == '/all-reviews' ? 'selected' : ''">
            <div class="selection" v-if="this.$route.path == '/all-reviews'"></div>
            <div class="col-10">
            <i class="bi bi-stack navIcon"></i>
            <span>{{ $t("All reviews")}}</span>
            </div>
        </div>

        <div class="row navRow mb-4" @click="navClick(4)" :class="this.$route.path == '/reports' ? 'selected' : ''">
            <div class="selection" v-if="this.$route.path == '/reports'"></div>
            <div class="col-10">
            <i class="bi bi-pie-chart navIcon"></i>
            <span>{{ $t("Reports")}}</span>
            </div>
        </div>
        </div>

        <div class="bottomBlock">
        <div class="row navRow" @click="navClick(5)" :class="currentNav == 5 ? 'selected' : ''">
            <div class="selection" v-if="currentNav == 5"></div>
            <div class="col-10">
            <i class="bi bi-question-circle navIcon"></i>
            <span>{{ $t("Help")}}</span>
            </div>
        </div>

        <div class="row navRow mt-4" @click="navClick(6)" :class="currentNav == 6 ? 'selected' : ''">
            <div class="selection" v-if="currentNav == 6"></div>
            <div class="col-10">
            <i class="bi bi-shield-lock navIcon"></i>
            <span>{{ $t("Privacy")}}</span>
            </div>
        </div>

        <LocaleChanger/>

        <div class="row navRow mt-4">
            <div class="col-10">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" role="switch" id="darkSwitcher" checked @click="modeToggle()">
                    <label for="darkSwitcher">{{ darkMode ? $t("Dark") : $t("Light") }}</label>
                </div>
            </div>
        </div>

        </div>

    </div>
<!-- End Left Nav -->
</template>

<script>
import LocaleChanger from "@/components/LocaleChanger.vue";

export default {
    name: 'LeftNav',
    components:
    {
        LocaleChanger
    },
    methods:
    {
        modeToggle()
        {
            this.$emit('modeToggle');
        },
        toggleNav()
        {
            this.navToggle = !this.navToggle;
        },
        getNavClass()
        {
            if (this.navToggle)
            {
                return "active";
            }
            else
            {
                return "";
            }
        },
        navClick(index)
        {
            this.$emit('navClick', index);
            this.currentNav = index;
        }, 
        setNav(index)
        {
            this.currentNav = index;
        }
    },
    data()
    {
        return {
            navToggle: true,
            currentNav: 0
        }
    },
    props:
    {
        darkMode: Boolean
    }
}
</script>