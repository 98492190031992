<template>
    <!-- Header -->
    <div class="row header align-items-center mb-3 mt-3">
            
        <!-- 9 -->
        <div class="col-9">
            <div class="row" style="height: 32px;">
                <div class="col-12" v-if="this.$route.path != '/new-review' && this.$route.path != '/reports'">
                    <span class="headerReviewing">{{ $t("Reviewing")}}</span>
                </div>
                <div class="col-12" v-else-if="this.$route.path == '/reports'">
                    <h2 class="newReviewTitle">{{ $t("Reports")}}</h2>
                </div>
                <div class="col-12" v-else>
                    <h2 class="newReviewTitle">{{ $t("New review")}}</h2>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <hr />
                </div>
            </div>
            <div class="row" style="height: 32px;"></div>
            <div class="row" v-if="this.$route.path != '/new-targets' && this.$route.path != '/reports' && this.$route.path != '/help' && this.$route.path != '/privacy'">
                <div class="col">
                    <h4 class="headerUsername">{{ getCurrentUser }}</h4>
                    <UserDropDown @selectUser="selectUser"></UserDropDown>
                </div>
            </div>
        </div>

        <!-- 3 -->
        <div class="col-3">
            <div class="row align-items-center">
                <div class="autoWidth">
                    <span class="accountName">{{ this.$store.state.newUserModel.first_name + ' ' + this.$store.state.newUserModel.last_name }}</span> <!--{{ this.$route.path }}-->
                </div>
                <div class="autoWidth">
                    <div class="headerIcon" data-bs-toggle="dropdown" aria-expanded="false">{{ this.$store.state.newUserModel.first_name.charAt(0) + this.$store.state.newUserModel.last_name.charAt(0) }}</div>
                    <div class="dropdown">
                        <ProfileDropDown ref="profileDropdown" @logout="this.$emit('logout')" @navClick="navClick"></ProfileDropDown>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Header -->
</template>

<style scoped>
.autoWidth
{
    width: auto;
}

.newReviewTitle
{
    color: white;
    font-size: 28px;
}
</style>

<script>

import UserDropDown from "./UserDropDown.vue";
import ProfileDropDown from "./ProfileDropDown.vue";
import axios from 'axios';

export default {
    name: 'HeaderComponent',
    components:
    {
        UserDropDown,
        ProfileDropDown
    },
    methods:
    {
        selectUser(userId)
        {
            if (this.$route.path == '/reports')
            {
                console.log("[Reports] - Selected user: " + userId);
            }
            else if (this.$route.path == '/all-reviews')
            {
                console.log("[All Reviews] - Selected user: " + userId);
                this.$store.commit('setAllReviewsSelectedUser', userId);

                // Load forms for this new user
                axios.get(this.$store.state.kivernoAPIEndPoint + 'getAllFormsForReportee/' + userId).then((response) => 
                {
                    console.log(response.data);
                    this.$store.commit('setAllReviewsSelectedUserForms', response.data);
                }).catch((error) => console.log(error));
            }
            else if (this.$route.path == '/new-review')
            {
                console.log("[New review] - Change user in new review to: " + userId);
                this.$store.commit('setNewReviewSelectedUser', userId);
            }
            else
            {
                console.log("[Dashboard] - Selected user: " + userId);
                this.$store.commit('setDashboardSelectedUser', userId);
            }
        },
        toggleProfileDropdown()
        {
            
        },
        navClick(index)
        {
            this.$emit('navClick', index);
        }
    },
    data()
    {
        return {
            
        }
    },
    computed:
    {
        getCurrentUser()
        {
            if (this.$route.path == '/all-reviews')
            {
                if (this.$store.state.allReviewsSelectedUser != null)
                {
                    return this.$store.state.newUserModel.sub_users.find(item => item.id === this.$store.state.allReviewsSelectedUser).username;
                }
                return this.$store.state.newUserModel.first_name + ' ' + this.$store.state.newUserModel.last_name;
            }
            else if (this.$route.path == '/new-review')
            {
                if (this.$store.state.newReviewSelectedUser != null && this.$store.state.newReviewSelectedUser != this.$store.state.newUserModel.id)
                {
                    return this.$store.state.newUserModel.sub_users.find(item => item.id === this.$store.state.newReviewSelectedUser).username;
                }

                return this.$store.state.newUserModel.first_name + ' ' + this.$store.state.newUserModel.last_name;
            }
            else
            {
                if (this.$store.state.dashboardSelectedUser == this.$store.state.newUserModel.id)
                {
                    this.$store.commit('setDashboardSelectedUser', null);
                    this.$store.commit('setDashboardSelectedForm', null);
                    this.$store.commit('setDashboardSelectedReportData', null);
                }
                else if (this.$store.state.dashboardSelectedUser != null)
                {
                    return this.$store.state.newUserModel.sub_users.find(item => item.id === this.$store.state.dashboardSelectedUser).username;
                }
                this.$store.commit('setNewReviewSelectedUser', this.$store.state.newUserModel.id);
                return this.$store.state.newUserModel.first_name + ' ' + this.$store.state.newUserModel.last_name;
            }
        }
    },
    props:
    {
        firstname: String,
        lastname: String,
        formRepoFilterData: {}
    }
}
</script>