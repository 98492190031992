<template>
  
    <div class="p-2 component">
      <div class="row mt-2">
        <div class="col-6">
          <h2>{{ $t("My targets")}}</h2>
        </div>
        <div class="col-6">
          <div class="row smallButtonRow">
            <div class="col-3 smallButton">
              <button class="" @click="this.$emit('navClick', 2)">{{ $t("Update")}}</button>
            </div>
            <div class="col-3 smallButton inverted">
              <button class="" @click="this.$emit('navClick', 2)">{{ $t("New")}}</button>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <QuartersDropDown :available_quarters="getTheAvailableQuarters" @selectQuarter="selectQuarter"></QuartersDropDown>
        </div>
      </div>

      <!-- Target Row -->

      <div class="nullWrapper mt-3">
        <div class="row targetRow" v-for="(n, index) in getTargets()" :key="index">
          <div class="col-8">
            <p>{{ n.target_name }}</p>
          </div>

          <div class="col-4">
            <table>
              <tr>
                <td><p class="pending">{{ n.target_status == 0 ? 'Pending' : 'Complete' }}</p></td>
                <td><p class="pending" v-if="n.target_date">{{ getQuarter(new Date(n.target_date)) }}</p></td><!-- * 1000-->
                <td><img src="../assets/img/lr-arrow.svg" class="target-arrow" /></td>
                <td><div class="numberCircle">{{ n.target_value }}</div></td>
              </tr>
            </table>
            
          </div>
        </div>
      </div>
      <!-- End Target Row -->
    </div>
</template>

<script>
import helpers from '@/helpers/helpers'
import QuartersDropDown from "./QuartersDropDown.vue";

export default {
    mixins: [helpers],
    name: 'MyTargets',
    components:
    {
        QuartersDropDown
    },
    methods:
    {
        selectQuarter(quarter)
        {
          this.selectedQuarter = quarter;
          //console.log("*** My targets filter to: " + quarter);
        },
        getTargets()
        {
          var targets = [];

          if (this.$store.state.dashboardSelectedUser != null)
          {
            if (this.$store.state.dashboardSelectedForm != null && this.$store.state.dashboardSelectedForm.targets != null)
            {
              for (var c = 0; c < this.$store.state.dashboardSelectedForm.targets.length; c++)
              {
                if (this.$store.state.dashboardSelectedForm.targets[c].target_quarter == this.selectedQuarter && this.$store.state.dashboardSelectedForm.is_self && this.$store.state.dashboardSelectedForm.targets[c].target_value != "0")
                {
                  targets.push(this.$store.state.dashboardSelectedForm.targets[c]);
                }
              }
            }
            
          }
          else
          {
            for (var a = 0; a < this.$store.state.newUserModel.user_forms.length; a++)
            {
              for (var b = 0; b < this.$store.state.newUserModel.user_forms[a].targets.length; b++)
              {
                if (this.$store.state.newUserModel.user_forms[a].targets[b].target_quarter == this.selectedQuarter && this.$store.state.newUserModel.user_forms[a].is_self && this.$store.state.newUserModel.user_forms[a].targets[b].target_value != "0")
                {
                  targets.push(this.$store.state.newUserModel.user_forms[a].targets[b]);
                }
              }
            }
          }
          return targets;
        },
        getStatusString(status)
        {
          if (status == 2)
          {
            return 'Done';
          }
          else if (status == 1)
          {
            return "Pending";
          }
          return "";
        }
    },
    data () {
        return {
          selectedQuarter: ""
        }
    },
    computed:
    {
      getTheAvailableQuarters()
      {
        var available = [];

        for (var a = 0; a < this.$store.state.newUserModel.user_forms.length; a++)
        {
          if (this.$store.state.newUserModel.user_forms[a].is_self)
          {
            for (var b = 0; b < this.$store.state.newUserModel.user_forms[a].targets.length; b++)
            {
              if (this.$store.state.newUserModel.user_forms[a].targets[b].target_date != "" && this.$store.state.newUserModel.user_forms[a].targets[b].target_date != undefined)
              {
                //console.log("Adding Quarter: " + this.$store.state.newUserModel.user_forms[a].targets[b].target_quarter);
                var found = false;
                for (var c = 0; c < available.length; c++)
                {
                  if (available[c].q == this.$store.state.newUserModel.user_forms[a].targets[b].target_quarter)
                  {
                    found = true;
                  }
                }
                if (!found)
                {
                  available.push({ "q": this.$store.state.newUserModel.user_forms[a].targets[b].target_quarter, "d": this.$store.state.newUserModel.user_forms[a].targets[b].target_date });
                }
                
              }
            }
          }
        }

        available.sort((firstItem, secondItem) => firstItem.d - secondItem.d);
        //console.log("***** Returned available quarters: " + JSON.stringify(available));
        return available;
      },
    },
    props:
    {
       
    }
}
</script>