<template>
<!-- Login Form -->
<div class="col-8">
    <div class="row">
      <div class="col-12">
        <h1>{{$t('Hello! Welcome back')}}</h1>
      </div>

      <div class="row" v-if="!forgot_password">
        <div class="col-12">
          <p>{{$t('Login below with your email address and password you created during account registration')}}</p>
        </div>
      </div>
      <div class="row" v-if="forgot_password">
        <div class="col-12">
          <p>{{$t('Enter your email address below to reset your password')}}</p>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <label>{{$t('Email address')}}</label>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <input type="email" id="login_email" name="login_email" :placeholder="$t('email_placeholder')" v-model="loginUserName" />
        </div>
      </div>

      <div class="row mt-3" v-if="!forgot_password">
        <div class="col-6">
          <label>{{$t('Password')}}</label>
        </div>
        <div class="col-6">
          <p style="width: 100%; padding: 0; margin: 0; text-align: right;"><small><a @click="this.forgotPassword()">{{$t('Forgot Password?')}}</a></small></p>
        </div>
      </div>

      <div class="row" v-if="!forgot_password">
        <div class="col-12">
          <input type="password" id="login_password" name="login_password" :placeholder="$t('Enter your password')" v-model="loginPassword"  @keyup.enter="this.$emit('login', this.loginUserName, this.loginPassword)"/>
        </div>
      </div>

      <div class="row mt-3" v-if="!forgot_password">
        <div class="col-12">
          <button @click="this.$emit('login', this.loginUserName, this.loginPassword)">{{$t('Login')}}</button>
        </div>
      </div>
      <div class="row mt-3" v-if="forgot_password">
        <div class="col-12">
          <button @click="this.resetPassword()">{{$t('Reset Password')}}</button>
        </div>
      </div>
      <div class="row mt-3" v-if="forgot_password">
        <div class="col-12">
          <p><small><a @click="this.cancelPasswordReset()">{{$t('Cancel')}}</a></small></p>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <p><small>{{$t('Don’t have an account yet?')}} &nbsp;&nbsp;<a @click="this.$emit('toggleLogin')">{{$t('Register')}}</a></small></p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

import axios from 'axios'

export default {
    name: 'LoginForm',
    components:
    {
      
    },
    data()
    {
      return {
        loginUserName: "",
        loginPassword: "",
        /*loginUserName: "ch@vividatom.com",
        loginPassword: "Bast0462!",*/
        forgot_password: false
      }
    },
    props:
    {
      
    },
    methods:
    {
      resetPassword()
      {
        axios.post(this.$store.state.kivernoAPIEndPoint + 'resetPassword/1', {email: this.loginUserName}).then((response) => 
        {
          console.log(response);
          if (response.data.ok)
          {
            alert("A password reset email has been sent")
          }
        }).catch((error) => 
        {
          if (error.response)
          {
            alert(error.response.data.error);
          }
          
        });
      },
      forgotPassword()
      {
        this.forgot_password = true;
      },
      cancelPasswordReset()
      {
        this.forgot_password = false;
      }
    }
}
</script>