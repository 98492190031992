<template>

    <div class="dropdown">
        <button class="btn" style="width: 80px;" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"><i class="bi bi-chevron-down"></i></button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            
            <div class="userContainer container">
                <div class="row menuUserRow mb-2" @click="selectUser(this.$store.state.newUserModel.id)">
                    <div class="col-3">
                        <div class="iconWrapper">
                            <i class="bi bi-person"></i>    
                        </div>
                    </div>
                    <div class="col-9 userDetails">
                        <p>{{ $t("Myself")}}: <b>{{ this.$store.state.newUserModel.first_name + ' ' + this.$store.state.newUserModel.last_name }}</b></p>
                        <p>{{ Array.prototype.map.call(this.$store.state.newUserModel.kiverno_role, function(item) { return item.title; }).join(", ") }}</p>
                    </div>
                </div>

                
                <div class="row">
                    <MyReportees :formRepoFilterData="formRepoFilterData" @selectUser="selectUser"></MyReportees>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import MyReportees from "./MyReportees.vue"

export default {
    name: 'UserDropDown',
    components:
    {
        MyReportees
    },
    methods:
    {
        selectUser(userId)
        {
            this.$emit('selectUser', userId);
        }
    },
    data()
    {
        return {
            
        }
    },
    props:
    {
        formRepoFilterData: {}
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

.btn
{
    background-color: #0F3339;
    width: 30px !important;
    height: 26px;
    padding: 0;
    margin: 0;
    margin-left: 10px;
    margin-top: -8px;
}

.dd-title
{
    font-size: 10px;
    font-family: $fonts;
    font-weight: $regular;
    color: #B7C3DB;
}
.menuUserRow
{
    //padding-left: 10px;
    //padding-right: 10px;
    cursor: pointer;
}
.menuUserRow p
{
    font-size: 12px;
    font-weight: $regular;
    color: #808A9D;
    padding: 0;
    margin: 0;
}
.menuUserRow:hover
{
    transition: background-color 0.45s ease;
    background-color: #293A50;
}

.menuUserRow p b
{
    color: white;
}

.jobTitle
{
    font-weight: $regular;
    color: #808A9D;
}

.btn i
{
    color: #12CD90;
}
.dropdown
{
    display: inline-block;
}

.dropdown-menu
{
    width: 284px;
    background-color: #32405C;
    border: none;
}

.iconWrapper
{
    background-color: #1C394B;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-radius: 50px;
}

.iconWrapper i
{
    font-size: 32px;
    color: #1B5858;
}

.userDetails
{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

</style>
